<template>
    <div class="sticky-btn" :class="{active:isShow}">
        <div class="flex items-center justify-between gap-[12px] mb-[14px]">
            <div class="sticky-btn__nav prev">
                <button :disabled="activeIndex===0" class="sticky-btn__nav-btn" @click="productNav('decrement')">
                    <span class="sticky-btn__nav-btn-icon"></span>
                </button>
                <span class="text-[24px]/[33px] font-bold text-defaultTextColor">{{ selectedProduct?.product_units || 0 }}</span>
                <button :disabled="products.length-1 ===activeIndex" class="sticky-btn__nav-btn next" @click="productNav('increment')">
                    <span class="sticky-btn__nav-btn-icon next"></span>
                </button>
            </div>
            <div class="flex items-center justify-center flex-wrap gap-[10px]">
                <div v-if="selectedProduct?.percentage_discount" class="flex py-[3px] px-[6px] rounded-[8px] bg-secondary-14">
                    <span class="text-teal text-[18px] leading-[25px] font-[600] mr-[2px]">{{ selectedProduct.percentage_discount }}% </span>
                    <span class="text-text text-[10px] leading-[14px]">off</span>
                </div>
                <div class="relative">
                    <span class="text-[20px] leading-[31px] font-[600] pr-[13px]">${{ selectedProduct?.base_price }}</span>
                    <span v-if="selectedProduct?.strikethrough_price" class="absolute right-0 -top-[10px] text-[10px] leading-[14px] line-through text-secondary-350 font-[700]">${{ selectedProduct.strikethrough_price }}</span>
                </div>
            </div>
        </div>

        <button @click.prevent="nextStep" href="#" class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between mt-auto">
            <span class="info text-[14px] font-bold">
                Continue
            </span>
            <span class="ico ico-24 ml-[10px]">
                <img src="@/assets/img/icons/icon-right-arrow.svg" style="color: #fff;" alt="arrow">
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'StickyButton',
    props: {
        products: {
            type: Array
        },
        activeGroup:{
            type:String
        },
        isShow:{ 
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            selectedProduct: null,
            activeIndex: 0
        }
    },
    methods: {
        nextStep() {
            if (!this.selectedProduct) {
                return
            }

            this.$bus.updateDataprops('platformConfig', { selectedProduct: this.selectedProduct, groupIdentifier: this.activeGroup })
            this.$router.push(`/tiktok/${this.$route.params.type}`)
        },

        productNav(type) {
            type === 'increment' ? this.activeIndex++ : this.activeIndex--
            this.selectedProduct = this.products[this.activeIndex]
        }
    },
    watch: {
        'products': {
            handler() {
                this.activeIndex = 0
                this.selectedProduct = this.products[this.activeIndex]
            },
            deep: true
        }
    },
    mounted() {
        this.activeIndex = 0
        this.selectedProduct = this.products[this.activeIndex]
    }
}
</script>

<style scoped>
.sticky-btn {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);

    width: 100%;
    background-color: var(--white);

    box-shadow: 0px 0px 10px #CFD5DE;

    padding:22px 32px 14px 32px;
    transition: transform .4s ease-in-out;
}

.sticky-btn.active {
    transform: translateX(-50%) translateY(0%);
    transition: transform .4s ease-in-out;
}

.sticky-btn__nav {
    min-width: 145px;
    width: max-content;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.sticky-btn__nav-btn {
    flex: 0 0 31px;
    width: 31px;
    height: 31px;

    background-color: var(--border);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color .3s ease-in-out;
}

.sticky-btn__nav-btn:disabled{
    cursor: auto;
    pointer-events: none;

    opacity: .3;
}

.sticky-btn__nav-btn:hover {
    background-color: var(--primary-400);
    transition: background-color .3s ease-in-out;
}

.sticky-btn__nav-btn-icon {
    position: relative;
    width: 11px;
    height: 2px;
    background-color: var(--defaultTextColor);
    border-radius: 25%;
}

.sticky-btn__nav-btn-icon.next::after {
    content: '';
    display: block;
    width: 11px;
    height: 2px;

    background-color: var(--defaultTextColor);
    border-radius: 25%;

    transform: rotate(90deg);
}
</style>