<template>
    <aside class="sidebar d-none-1024">
        <div class="sidebar__container">
            <router-link to="/service-page/likes" href="" class="sidebar__logo">
                <img src="@/assets/img/tinkws-logo.svg" alt="">
            </router-link>
            <p class="sidebar-title mt-[9px] mb-[9px]">TikTok Services</p>
            <ul class="sidebar__list">
                <li>
                    <router-link to="/service-page/followers">
                        <span class="sidebar-list-icon">
                            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6 7.63291C8.36731 7.63291 9.8 6.20022 9.8 4.43291C9.8 2.6656 8.36731 1.23291 6.6 1.23291C4.83269 1.23291 3.4 2.6656 3.4 4.43291C3.4 6.20022 4.83269 7.63291 6.6 7.63291ZM6.6 2.83291C7.48366 2.83291 8.2 3.54925 8.2 4.43291C8.2 5.31657 7.48366 6.03291 6.6 6.03291C5.71634 6.03291 5 5.31657 5 4.43291C5 3.54925 5.71634 2.83291 6.6 2.83291ZM15.4 6.83291C15.4 8.15839 14.3255 9.23291 13 9.23291C11.6745 9.23291 10.6 8.15839 10.6 6.83291C10.6 5.50743 11.6745 4.43291 13 4.43291C14.3255 4.43291 15.4 5.50743 15.4 6.83291ZM13.8 6.83291C13.8 6.39108 13.4418 6.03291 13 6.03291C12.5582 6.03291 12.2 6.39108 12.2 6.83291C12.2 7.27474 12.5582 7.63291 13 7.63291C13.4418 7.63291 13.8 7.27474 13.8 6.83291ZM10.552 10.8729C11.2524 10.3294 12.1135 10.0339 13 10.0329C15.2091 10.0329 17 11.8238 17 14.0329C17 14.4747 16.6418 14.8329 16.2 14.8329C15.7582 14.8329 15.4 14.4747 15.4 14.0329C15.3988 13.1135 14.8725 12.2755 14.0448 11.8753C13.217 11.475 12.2334 11.5829 11.512 12.1529C11.9651 12.9733 12.2019 13.8957 12.2 14.8329C12.2 15.2747 11.8418 15.6329 11.4 15.6329C10.9582 15.6329 10.6 15.2747 10.6 14.8329C10.6 12.6238 8.80914 10.8329 6.6 10.8329C4.39086 10.8329 2.6 12.6238 2.6 14.8329C2.6 15.2747 2.24183 15.6329 1.8 15.6329C1.35817 15.6329 1 15.2747 1 14.8329C1.00306 12.5713 2.36619 10.5335 4.45538 9.66742C6.54457 8.8013 8.94966 9.27686 10.552 10.8729Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Followers
                    </router-link>
                </li>
                <li>
                    <router-link to="/service-page/likes">
                        <span class="sidebar-list-icon">
                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28793 2.21542L8.58093 1.50842L8.57993 1.50942C7.32228 0.219669 5.46806 -0.293854 3.7261 0.165159C1.98413 0.624171 0.623745 1.98475 0.164979 3.72678C-0.293788 5.46881 0.219997 7.32296 1.50993 8.58042L8.58093 15.6504C8.7685 15.8382 9.02302 15.9437 9.28843 15.9437C9.55384 15.9437 9.80836 15.8382 9.99593 15.6504L17.0659 8.57942C18.3383 7.31817 18.8386 5.47257 18.3774 3.74139C17.9163 2.01022 16.5641 0.658084 14.833 0.196925C13.1018 -0.264234 11.2562 0.236073 9.99493 1.50842L9.28793 2.21542ZM9.28793 13.5304L2.92393 7.16642C1.76876 5.99201 1.77666 4.10582 2.94163 2.94113C4.1066 1.77643 5.99278 1.76897 7.16693 2.92442L9.28793 5.04442L11.4089 2.92342C12.1669 2.16548 13.2716 1.86947 14.307 2.1469C15.3423 2.42432 16.151 3.23303 16.4285 4.2684C16.7059 5.30376 16.4099 6.40848 15.6519 7.16642L9.28793 13.5304Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Likes
                    </router-link>
                </li>
                <li>
                    <router-link to="/service-page/views">
                        <span class="sidebar-list-icon">
                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.99999C0 11.3139 4.7101 14.0069 9.5 13.9999C14.2899 13.9929 19 11.3139 19 7.99999C19 4.68604 14.2652 2.01708 9.5 2.00008C4.7348 1.98308 0 4.68604 0 7.99999ZM9.49335 4.00005L9.49446 4.00005C7.39832 4.0032 5.7 5.79283 5.7 7.99999C5.7 10.2083 7.40004 11.9986 9.49765 11.9999L9.49715 11.9999C5.4663 12.0059 1.9 9.82496 1.9 7.99999C1.9 6.17802 5.48625 3.98505 9.49335 4.00005ZM9.49685 9.99995L9.49765 11.9999L9.5 11.9999L9.49861 9.99995C9.49908 9.99995 9.49954 9.99996 9.5 9.99996C10.5493 9.99996 11.4 9.10454 11.4 7.99999C11.4 6.89543 10.5493 6.00002 9.5 6.00002C9.49861 6.00002 9.49723 6.00002 9.49584 6.00002L9.49446 4.00005L9.55354 4.00043C11.6275 4.03058 13.3 5.8097 13.3 7.99999C13.3 10.2011 11.6109 11.987 9.5227 11.9999C13.5544 11.9848 17.1 9.81612 17.1 7.99999C17.1 6.19902 13.5635 4.03668 9.55354 4.00043C9.53572 4.00017 9.51787 4.00005 9.5 4.00005L9.49446 4.00005L9.49525 6.00002C8.4481 6.00272 7.6 6.8971 7.6 7.99999C7.6 9.10343 8.44896 9.99816 9.49685 9.99995ZM9.49525 6.00002L9.49685 9.99995C9.49744 9.99995 9.49803 9.99995 9.49861 9.99995L9.49584 6.00002C9.49565 6.00002 9.49545 6.00002 9.49525 6.00002Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Views
                    </router-link>
                </li>
                <li>
                    <router-link to="/service-page/comments">
                        <span class="sidebar-list-icon">
                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.05689 15.7865C9.45244 13.7881 10.7324 12.7817 10.8969 12.7673H10.8978C14.8933 12.4105 18 9.69688 18 6.40325C18 2.86722 14.4178 0 10 0C5.58222 0 2 2.86722 2 6.40405C2 8.62007 3.51822 10.5697 5.54222 11.7209C5.55598 11.7286 5.55269 11.7526 5.54743 11.791C5.54182 11.832 5.53396 11.8894 5.54222 11.9609C5.57333 12.2257 5.58133 13.3033 5.56356 15.1929C5.56042 15.5123 5.76866 15.8027 6.09329 15.9318C6.41791 16.0608 6.7967 16.0037 7.05689 15.7865ZM10.7218 11.1745C10.0231 11.2369 9.73067 11.3921 7.35022 13.3617V13.3625C7.34933 12.6473 7.34044 12.1753 7.32089 11.9113C7.32487 11.8714 7.32753 11.8314 7.32889 11.7913C7.344 11.2625 7.18844 10.7649 6.49244 10.3689C4.82222 9.41687 3.77778 7.91766 3.77778 6.40325C3.77778 3.79603 6.52 1.60081 10 1.60081C13.48 1.60081 16.2222 3.79523 16.2222 6.40325C16.2222 8.80807 13.8791 10.8921 10.7218 11.1745Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Comments
                    </router-link>
                </li>
                <li>
                    <router-link to="/service-page/shares">
                        <span class="sidebar-list-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1992 12.2125C15.5146 12.5272 16.0253 12.5272 16.3407 12.2125V12.2133L18.0547 10.5026C18.6599 9.89853 19 9.07855 19 8.22346C19 7.36837 18.6599 6.5484 18.0547 5.94436C16.793 4.68521 14.7502 4.68521 13.4885 5.94436L9.49314 9.93341C9.03911 10.3865 8.78395 11.0015 8.78395 11.6429C8.78395 12.2844 9.03911 12.8994 9.49314 13.3525C9.8086 13.6671 10.3192 13.6671 10.6347 13.3525C10.7861 13.2014 10.8712 12.9964 10.8712 12.7825C10.8712 12.5686 10.7861 12.3635 10.6347 12.2125C10.4835 12.0615 10.3986 11.8566 10.3986 11.6429C10.3986 11.4293 10.4835 11.2244 10.6347 11.0734L14.6284 7.08433C15.2593 6.45498 16.2806 6.45498 16.9115 7.08433C17.2141 7.38635 17.3841 7.79633 17.3841 8.22388C17.3841 8.65142 17.2141 9.06141 16.9115 9.36343L15.1992 11.0734C15.048 11.2244 14.9631 11.4293 14.9631 11.6429C14.9631 11.8566 15.048 12.0615 15.1992 12.2125ZM7.79999 12.7875C7.48452 12.4728 6.97391 12.4728 6.65844 12.7875V12.7867L4.9453 14.4974C4.3401 15.1015 4 15.9214 4 16.7765C4 17.6316 4.3401 18.4516 4.9453 19.0556C6.20698 20.3148 8.24978 20.3148 9.51147 19.0556L13.5069 15.0666C13.9609 14.6135 14.216 13.9985 14.216 13.3571C14.216 12.7156 13.9609 12.1006 13.5069 11.6475C13.1914 11.3329 12.6808 11.3329 12.3653 11.6475C12.2139 11.7986 12.1288 12.0036 12.1288 12.2175C12.1288 12.4314 12.2139 12.6365 12.3653 12.7875C12.5165 12.9385 12.6014 13.1434 12.6014 13.3571C12.6014 13.5707 12.5165 13.7756 12.3653 13.9266L8.37076 17.9157C7.73982 18.545 6.71861 18.545 6.08767 17.9157C5.78507 17.6137 5.61502 17.2037 5.61502 16.7761C5.61502 16.3486 5.78507 15.9386 6.08767 15.6366L7.79999 13.9266C7.95116 13.7756 8.0361 13.5707 8.0361 13.3571C8.0361 13.1434 7.95116 12.9385 7.79999 12.7875Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Shares
                    </router-link>
                </li>
            </ul>
            <ul class="sidebar__list">
                <li>
                    <a @click.prevent="logOut" href="">
                        <span class="sidebar-list-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0952 3.9049C10.0952 4.16789 10.3084 4.38109 10.5714 4.38109H18.1905C18.9794 4.38109 19.619 5.02068 19.619 5.80966V18.1906C19.619 18.9796 18.9794 19.6192 18.1905 19.6192H10.5714C10.3084 19.6192 10.0952 19.8324 10.0952 20.0954C10.0952 20.3584 10.3084 20.5716 10.5714 20.5716H18.1905C19.5054 20.5716 20.5714 19.5056 20.5714 18.1906V5.80966C20.5714 4.4947 19.5054 3.42871 18.1905 3.42871H10.5714C10.3084 3.42871 10.0952 3.64191 10.0952 3.9049ZM8.05099 14.9968L5.05437 12.0001H15.3333C15.5963 12.0001 15.8095 11.7869 15.8095 11.5239C15.8095 11.261 15.5963 11.0478 15.3333 11.0478H5.05437L8.05099 8.05114C8.23695 7.86518 8.23695 7.56367 8.05099 7.37771C7.86503 7.19174 7.56352 7.19174 7.37755 7.37771L3.56803 11.1872C3.38207 11.3732 3.38207 11.6747 3.56803 11.8607L7.37755 15.6702C7.56352 15.8562 7.86503 15.8562 8.05099 15.6702C8.23695 15.4842 8.23695 15.1827 8.05099 14.9968Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        Log Out
                    </a>
                </li>
            </ul>
            <router-link to="/affiliate" class="hover:opacity-70">
                <p class="sidebar-subtext">Affiliate dashboard</p>
            </router-link>
        </div>
    </aside>
</template>

<script>
export default {
    methods: {
        logOut() {
            window.localStorage.removeItem('token')

            this.$bus.updateDataprops('user', null)
            this.$route.params?.type ? '':this.$router.push('/service-page/likes')
        }
    }
}
</script>

<style>
    .sidebar__list li a.router-link-exact-active .sidebar-list-icon {
        background-color: rgba(254, 44, 85, 1);
        color: #fff;
    }
</style>