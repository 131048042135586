<template>
    <div class="steps-flex flex flex-col" :class="{ 'isPopUp': isPopUp }">
        <!-- <div class="step-flex mb-[-50px]" v-if="!tiktokPlatform">
            
        </div> -->
        <div class="step-flex mb-[-50px]">
            <div v-if="!tiktokPlatform" class="step-flex--item step-products pb-[50px] min-h-[494px]">
                <div>Loading</div>
            </div>
            <div class="step-flex--item step-products pb-[50px]" v-else>

                <div v-if="groupIdentifiers.length > 1" class="tabs-head d-md-none mb-[20px]" >
                    <ul class="tabs__list whitespace-nowrap">
                        <li class="w-full" v-for="(item, index) in groupIdentifiers" :key="index">
                            <a @click.prevent="activeGroup = item.identifier" href="#" class="tab-button" :class="{ 'active': activeGroup === item.identifier }">
                                <span class="tab-text">{{ item.name }}</span>
                            </a>
                        </li>

                    </ul>
                    <div v-click-outside="closeDropdown" class="dropdown custom_dropdown" :class="{ 'dropdown-open': isDropdownOpen }" data-dropdown="dropdown">
                        <a @click.prevent="toggleDropdown" href="#" class="btn" data-role="button">
                            <p class="difference-text">What is the difference?</p>
                        </a>
                        <div class="dropdown__body rounded-[13px] px-6 py-10 bg-primary-100 custom_dropdown_body" :class="{ 'is-open': isDropdownOpen }" data-role="dropdown">
                            <div class="text-center">
                                <p class="font-semibold text-text whitespace-normal">
                                    {{ tooltipText }}
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <RedProductCard
                 v-if="isSecondGroup" 
                 :products="getProducts" 
                 :platform="activePlatformType"
                 :sliceProducts="sliceProducts"
                 @change="(value) => selectedProduct = value"
                 />

                <div v-else class="choices-comment w-full">
                    <div class="choices-grid-wrap">
                        <div class="w-full h-full" v-for="(product, index) in getProducts.slice(0, sliceProducts)" :key="index">
                            <label class="choice-label h-full flex" @click="selectedProduct = product">
                                <input type="radio" name="product" :checked="product === selectedProduct" class="choice-input" style="display:none">
                                <div class="choices-comment__item h-full w-full" :class="{ 'second-package': isSecondGroup }">
                                    <span class="choices-comment__icon">
                                        <img src="@/assets/img/icons/icon-tiktok-logo.svg" alt="icon-tiktok-logo">
                                    </span>
                                    <span class="choices-comment__amount" :class="{ popup: isPopUp }">{{ product.product_units }} <span>{{ activePlatformType }} </span> </span>
                                    <span class="choices-comment__price">${{ product.base_price }}
                                        <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]" v-show="product.strikethrough_price">
                                            {{ product.strikethrough_price }}
                                        </span>
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div v-if="getProducts.length > 6" class="w-full h-full">
                            <label class="choice-label h-full flex" @click="selectedProduct = dropDownOption">
                                <input type="radio" name="product" class="choice-input" :checked="dropDownOption === selectedProduct" style="display:none">
                                <div class="choices-comment__item h-full w-full" :class="{ 'second-package': isSecondGroup }">
                                    <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg" alt="icon-tiktok-logo"></span>
                                    <!-- <span class="flex items-center relative dropdown" style="z-index:2;" data-dropdown="dropdown">
                                        <span class="choices-comment__amount mr-[3px]">5,000 {{activePlatformType}}</span>
                                        <a href="#" data-role="button" class="choices-comment__chevron">
                                            <img src="@/assets/img/icons/icon-chev.svg" alt="arrow">
                                        </a> -->
                                        <!-- <div class="dropdown__body choices-comment__body-amount" data-role="dropdown" style="width: 132px">
                                            <ul class="choices-comment__amount-list">
                                                <li class="choices-comment__amount-item">
                                                    <a href="#"> 5,000</a>
                                                </li>
                                                <li class="choices-comment__amount-item">
                                                    <a href="#">10,000</a>
                                                </li>
                                                <li class="choices-comment__amount-item">
                                                    <a href="#">25,000</a>
                                                </li>
                                            </ul>

                                            :value="selectedData[item.model]"
                                            @change="selectedData[item.model] = $event.value"

                                        </div> -->
                                        
                                    <!-- </span> -->

                                    <Dropdown
                                            :title="'title'"
                                            class="product-select-drop"
                                            :isProductDropdown="true"
                                            :options="getProducts.slice(5).map(el => ({ title: `${el.product_units} ${activePlatformType}`, value: el }))"
                                            :value="dropDownOption"
                                            :isModal="isPopUp"
                                            @change="dropDownOption = $event.value; selectedProduct = $event.value"
                                        />
                                    <span class="choices-comment__price">
                                        ${{ dropDownOption.base_price }}
                                        <!-- <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]">
                                            $52.99
                                        </span> -->
                                        <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]" v-show="dropDownOption.strikethrough_price">
                                            {{ dropDownOption.strikethrough_price }}
                                        </span>
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="selectedProduct" class="flex items-center justify-center mb-[14px]">
                    <div v-if="selectedProduct.percentage_discount" class="flex py-[3px] px-[6px] rounded-[8px] bg-secondary-14 mr-[7px]">
                        <span class="text-teal text-[18px] leading-[25px] font-[600] mr-[2px]">{{ selectedProduct.percentage_discount }}% </span>
                        <span class="text-text text-[10px] leading-[14px]">off</span>
                    </div>
                    <span class="mr-[5px] text-[20px] leading-[31px] font-[600]">${{ selectedProduct.base_price }}</span>
                    <span v-if="selectedProduct.strikethrough_price" class="text-[10px] leading-[14px] line-through text-secondary-350 font-[700]">${{ selectedProduct.strikethrough_price }}</span>
                </div>
                <a @click.prevent="nextStep" href="#" class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between mt-auto">
                    <span class="info text-[14px] font-bold">
                        Continue
                    </span>
                    <span class="ico ico-24 ml-[10px]">
                        <img src="@/assets/img/icons/icon-right-arrow.svg" style="color: #fff;" alt="arrow">
                    </span>
                </a>
            </div>
            <div v-if="!isPopUp" class="step-flex--item step-content pb-[50px]">
                <div class="heading-block">
                    <h1 class="heading heading-1 font-bold">
                        Buy TikTok {{ activePlatformType }} with Instant delivery 🚀
                    </h1>
                    <div class="mt-[10px] mw-440">
                        <p class="def-text--2 font-semibold text-secondary-500">
                            Start getting TikTok likes without any hassle! 
                            Enter your username to receiving likes today!
                        </p>
                    </div>
                </div>
                <div class="global-rating mw-content py-[18px] px-[25px] rounded-[18px] mt-[30px]">
                    <div class="flex flex-wrap text-center mb-[-5px] items-center mx-[-4px]">
                        <p class="def-text--5 font-semibold pb-[5px] px-[4px]">4.7 / 5</p>
                        <div class="rating-input pb-[5px] px-[4px]" style="pointer-events:none;">
                            <input type="radio" checked name="rating" id="rating-5">
                            <label for="rating-5"></label>
                            <input type="radio" name="rating" id="rating-4">
                            <label for="rating-4"></label>
                            <input type="radio" name="rating" id="rating-3">
                            <label for="rating-3"></label>
                            <input type="radio" name="rating" id="rating-2">
                            <label for="rating-2"></label>
                            <input type="radio" name="rating" id="rating-1">
                            <label for="rating-1"></label>
                        </div>
                        <p class="def-text--5 font-semibold pb-[5px] px-[4px]">
                            2131 reviews
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Dropdown from '../Base/Dropdown.vue'
import RedProductCard from '../Base/RedProductCard.vue';

export default {
    props: ['activePlatformType', 'platforms', 'isPopUp'],
    components: {
        Dropdown, RedProductCard
    },
    data() {
        return {
            tiktokPlatform: null,
            activeGroup: null,
            dropDownOption: null,
            selectedProduct: null,
            groupIdentifiers: [],
            isDropdownOpen: false,
            sliceProducts: null
        }
    },
    watch: {
        'activePlatformType': {
            immediate: true,
            handler(newValue) {
                this.activeGroup = this.tiktokPlatform?.[this.activePlatformType].groupItems[0].identifier
                this.selectedProduct = null
                this.groupIdentifiers = []
            }
        },
        'platforms': {
            handler(newValue) {
                if (newValue) {
                    // console.log('New VALUE', newValue)
                    this.createGroup()
                }
            },
            deep: true,
            immediate: true
        },
        'getProducts': {
            handler(newValue) {
                this.selectedProduct = newValue[0]
                this.$emit('change', { products: newValue, group: this.activeGroup })
                // console.log('HELLO', newValue, this.selectedProduct)
            }
        }
    },
    computed: {
        getGroups() {
            return this.tiktokPlatform?.[this.activePlatformType]?.groupItems
        },
        getProducts() {
            const products = this.tiktokPlatform?.[this.activePlatformType].groupItems.find(el => el.identifier === this.activeGroup)?.products
            this.dropDownOption = products?.slice(5)[0]
            this.groupIdentifiers = this.tiktokPlatform?.[this.activePlatformType].groupItems.map(el => ({ identifier: el.identifier, name: el.type_name }))
            this.sliceProducts = products?.length > 6 ? 5 : products?.length || null

            return products
        },
        isSecondGroup() {
            return this.activeGroup === this.groupIdentifiers?.[1]?.identifier
        },
        tooltipText() {
            const texts = {
                'tiktok.randomComments': "Instant positivity! Pre-written, upbeat comments randomly selected from various categories like positive, humor, music, travel, and more. Choose your vibe effortlessly with this quick and easy option.",
                'tiktok.customComments': "Craft your engagement! With this service, write your own comments for each video, giving you complete control and personalization. While it demands more effort, it offers a uniquely tailored approach to boosting interaction on your content."
            }
            return texts?.[this.activeGroup] || ''
        }
    },
    methods: {
        createGroup() {
            const tiktok = this.platforms.filter(el => el.identifier === 'tiktok')[0]

            const group = {}
            tiktok.product_groups.forEach(el => {
                if (group[el.general_name.toLowerCase()] && !el.sub_groups) return

                group[el.general_name.toLowerCase() || el.name.toLowerCase()] = {
                    groupItems: el.sub_groups ? Object.keys(el.sub_groups).map(identifier => {
                        return tiktok.product_groups.find(item => item.identifier === identifier)
                    })
                        : [el]
                }
            })
            // console.log('GROUP', this.activePlatformType)
            this.tiktokPlatform = group
            this.activeGroup = group[this.activePlatformType].groupItems[0].identifier

            this.groupIdentifiers = this.tiktokPlatform?.[this.activePlatformType].groupItems.map(el => ({ identifier: el.identifier, name: el.type_name }))
            // console.log("HERE 123", this.tiktokPlatform, this.activeGroup, this.groupIdentifiers)
        },
        nextStep() {
            if (!this.selectedProduct) {
                return
            }
            // this.platformConfig.selectedProduct = this.selectedProduct
            this.$bus.updateDataprops('platformConfig', { selectedProduct: this.selectedProduct, groupIdentifier: this.activeGroup })
            // this.activeGroup.split('.')[1].toLowerCase()
            if (this.isPopUp) {
                this.$emit('nextStepPopUp')
            } else {
                this.$router.push(`/tiktok/${this.activePlatformType}`)
            }
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
            // console.log(this.activeGroup)
        },
    },
    mounted() {
        if(this.platforms) this.createGroup()
    }

}
</script>

<style>
.select__current.data-select-current {
    white-space: nowrap;
}

.second-package {
    border-color: #FE2C555A;
    background-color: #FFEFF2;
}

.choice-label .choice-input:checked+.choices-comment__item.second-package::before {
    background-color: #FFEFF2;
}

.difference-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #77838F;
    margin-top: 20px;
}

.custom_dropdown_body {
    position: absolute;
    z-index: 10;
    margin-top: 10px;
}

.custom_dropdown {
    position: relative;
    max-width: 300px;
    width: 300px;
    text-align: center;
}

.choices-grid-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 9px 11px;
    margin-bottom: 20px;
}


@media (max-width: 580px) {
    .select__current.data-select-current {
        white-space: unset;
    }

    .choices-comment__amount.popup span {
        display: block;
    }
}
</style>