<template>
    <div class="header-dash-mobile lg:hidden">
            <div class="container">
                <div class="header-dash-mobile-body">
                    <div class="header-dash-mobile-top" :class="{'mb-[24px]': !withOutNav}">
                        <router-link to="/" class="mobile-dash-logo no-bg">
                            <img src="@/assets/img/logo-tink-ws.png" alt="">
                        </router-link>
                        <template v-if="isLogged">

                            <router-link to="/cart" class="btn btn-header-card font-semibold ml-auto">
                            <span class="btn-icon relative mr-[12px]">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7646 3.55596C15.4742 3.01465 14.9409 2.67717 14.36 2.66711H3.78416L3.30495 0.658283C3.20525 0.258991 2.8628 -0.0136274 2.47871 0.000526357H0.826237C0.369919 0.000526357 0 0.398482 0 0.889386C0 1.38029 0.369919 1.77825 0.826237 1.77825H1.85077L4.13118 10.8979C4.23088 11.2972 4.57333 11.5699 4.95742 11.5557H12.3936C12.7047 11.5547 12.9889 11.3657 13.1289 11.0668L15.839 5.23591C16.0775 4.69793 16.0496 4.06712 15.7646 3.55596ZM11.8813 9.77798H5.58536L4.23859 4.44482H14.36L11.8813 9.77798ZM5.78366 14.6667C5.78366 15.4031 5.22878 16 4.5443 16C3.85983 16 3.30495 15.4031 3.30495 14.6667C3.30495 13.9304 3.85983 13.3334 4.5443 13.3334C5.22878 13.3334 5.78366 13.9304 5.78366 14.6667ZM12.8067 16C13.4911 16 14.046 15.4031 14.046 14.6667C14.046 13.9304 13.4911 13.3334 12.8067 13.3334C12.1222 13.3334 11.5673 13.9304 11.5673 14.6667C11.5673 15.4031 12.1222 16 12.8067 16Z" fill="currentColor"></path>
                                </svg>
                                <div class="indicator bg-primary-500"></div>
                            </span>
                                {{ cartTotal ? `$${cartTotal.toFixedNoRounding(2)}` : '$0.00' }}
                            </router-link>
                            <a href="" class="btn btn-burger ml-5" data-modal="#heared-mobile-not-log"
                               @click.prevent="openModal('header-mobile-menu')">
                                <img src="@/assets/img/setting-slider-horizontal.svg" alt="">
                            </a>
                        </template>


                        <div v-else class="header-buttons ml-auto" >
                            <a href="" class="btn text-primary-500 underline" @click.prevent="openModal('login')">
                                Log in
                            </a>
                            <a href="" class="btn px-[24px] py-[14px] bg-primary-500 rounded-[8px] text-white-100"
                               @click.prevent="openModal('register')">
                                Sign up
                            </a>
                        </div>


                    </div>
                    <nav v-if="!withOutNav" class="header-dash-mobile-nav">
                        <ul class="header-dash-mobile-menu">
                            <li>
                                <router-link to="/service-page/followers">Followers</router-link>
                            </li>
                            <li>
                                <router-link to="/service-page/likes">Likes</router-link>
                            </li>
                            <li>
                                <router-link to="/service-page/views">Views</router-link>
                            </li>
                            <li>
                                <router-link to="/service-page/comments">Comments</router-link>
                            </li>
                            <li>
                                <router-link to="/service-page/shares">Shares</router-link>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
</template>

<script>
export default {
    props: ["dataprops", "withOutNav"],
    data() {
        return {
            isLogged: true,
            cartTotal: null,
        }
    },
    watch: {
        'dataprops.user': {
            handler(newValue) {
                if (newValue) {
                    this.isLogged = true;
                }
            },
            deep: true,
            immediate: true
        },
        'dataprops.cartData.items': {
            handler(newVal) {
                if (this.dataprops?.cartData?.items?.length > 0) {
                    this.cartTotal = this.dataprops.cartData.totals.total
                }
            }
        },
        'dataprops.cartData': {
            handler(newVal) {
                if (!this.dataprops?.cartData) {
                    this.cartTotal = 0
                }
            }
        }
    },
    created() {
        if (this.dataprops?.cartData?.items?.length > 0) {
            this.cartTotal = this.dataprops.cartData.totals.total
        }
    },
    components:{
    },
    methods: {
        openModal(name){
            name==='header-mobile-menu'? document.body.classList.add('scroll-hidden'):''
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
    }
}
</script>

<style scoped>
.router-link-active {
    background-color: #fe2c55;
}

.no-bg {
    background-color: unset;
}
</style>