<template>
    <div  class="dropdown-username" :class="{error:error}" v-click-outside="closeDropdown">
        <div class="dropdown-username__content"  @click="openDropdown">
            <div class="dropdown-username__active-item">
                <span v-if="selectItem?.image" class="input-icon-user">
                    <img :src="selectItem.image" alt="user">
                </span>
                <svg v-else width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7112 11.1192C16.6285 11.1268 16.5451 11.1319 16.4611 11.1319C15.5097 11.1319 14.6735 10.6432 14.1867 9.90309C14.1867 11.8434 14.1867 14.0515 14.1867 14.0885C14.1867 15.7971 12.8014 17.1818 11.0934 17.1818C9.38536 17.1812 8 15.7958 8 14.0878C8 12.3792 9.38536 10.9945 11.0934 10.9945C11.1583 10.9945 11.2213 11.0002 11.2843 11.004V12.5287C11.2206 12.5211 11.1589 12.5096 11.0934 12.5096C10.2215 12.5096 9.51455 13.2166 9.51455 14.0885C9.51455 14.9603 10.2209 15.6673 11.0934 15.6673C11.9658 15.6673 12.7358 14.98 12.7358 14.1082C12.7358 14.0732 12.7511 7 12.7511 7H14.2077C14.3445 8.30264 15.3965 9.331 16.7112 9.42518V11.1192Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8 4H7.2C5.43269 4 4 5.43269 4 7.2V16.8C4 18.5673 5.43269 20 7.2 20H16.8C18.5673 20 20 18.5673 20 16.8V7.2C20 5.43269 18.5673 4 16.8 4ZM5.6 7.2C5.6 6.31634 6.31634 5.6 7.2 5.6H16.8C17.6837 5.6 18.4 6.31634 18.4 7.2V16.8C18.4 17.6837 17.6837 18.4 16.8 18.4H7.2C6.31634 18.4 5.6 17.6837 5.6 16.8V7.2Z" fill="black"/>
                </svg>
                <span :style="{opacity:selectItem?'1':'0.3'}">{{ selectItem? '@'+selectItem.name:'Your TikTok username' }}</span>
            </div>
        
            <span class="ico" :class="{active:activeDropdown}">
                <img src="@/assets/img/icons/ico-arrowdown.svg">
            </span>
        </div>
        
        <ul v-if="activeDropdown && options.length" class="dropdown-username__list">
            <li class="dropdown-username__list-item" v-for="(item, itemIndex) in options" :key="itemIndex" @click="chooseItem(item)">
                <div class="dropdown-username__item-content">
                    <div class="input-icon-user">
                        <img :src="item.image" alt="">
                    </div>
                    <span>@{{ item.name }}</span>
                </div>

                <img v-if="item.name===selectItem?.name"  src="@/assets/img/check-icon-red.svg" style="color: red;" alt="check">
            </li>
            <li class="dropdown-username__list-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7112 11.1192C16.6285 11.1268 16.5451 11.1319 16.4611 11.1319C15.5097 11.1319 14.6735 10.6432 14.1867 9.90309C14.1867 11.8434 14.1867 14.0515 14.1867 14.0885C14.1867 15.7971 12.8014 17.1818 11.0934 17.1818C9.38536 17.1812 8 15.7958 8 14.0878C8 12.3792 9.38536 10.9945 11.0934 10.9945C11.1583 10.9945 11.2213 11.0002 11.2843 11.004V12.5287C11.2206 12.5211 11.1589 12.5096 11.0934 12.5096C10.2215 12.5096 9.51455 13.2166 9.51455 14.0885C9.51455 14.9603 10.2209 15.6673 11.0934 15.6673C11.9658 15.6673 12.7358 14.98 12.7358 14.1082C12.7358 14.0732 12.7511 7 12.7511 7H14.2077C14.3445 8.30264 15.3965 9.331 16.7112 9.42518V11.1192Z" fill="black"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8 4H7.2C5.43269 4 4 5.43269 4 7.2V16.8C4 18.5673 5.43269 20 7.2 20H16.8C18.5673 20 20 18.5673 20 16.8V7.2C20 5.43269 18.5673 4 16.8 4ZM5.6 7.2C5.6 6.31634 6.31634 5.6 7.2 5.6H16.8C17.6837 5.6 18.4 6.31634 18.4 7.2V16.8C18.4 17.6837 17.6837 18.4 16.8 18.4H7.2C6.31634 18.4 5.6 17.6837 5.6 16.8V7.2Z" fill="black"/>
                    </svg>
                    
                    <input type="text" class="input font-semibold" placeholder="Enter username" @keydown="chooseUsername" v-model="newAccountName">
            </li>
        </ul>
        
     
    </div>
</template>

<script>
    export default {
        name:'DropdownUsername',
        props:{
            options:{
                type:Array,
            },
            error:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                updateKey: 0,
                activeDropdown:false,
                newAccountName:'',
                selectItem:null
            }
        },
        methods:{
            closeDropdown(){
                this.activeDropdown = false
            },
            openDropdown(){
                this.activeDropdown = !this.activeDropdown
            },
            chooseItem(item){
                this.selectItem = item
                this.closeDropdown()
                this.$emit('change', item.name)
            },
            chooseUsername(ev){
                if(ev.keyCode===13){
                    this.selectItem = {name:this.newAccountName}
                    this.$emit('change', this.newAccountName)
                    this.newAccountName = ''
                    this.closeDropdown()
                } 
            }
        },
    }
</script>

<style scoped>

.dropdown-username{
    position: relative;
    cursor: pointer;

    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(200,206,218, 0.375082);

    padding: 18px 13px;
}

.dropdown-username.error{
    border-color: var(--primary-500);
    background-color: var(--primary-200);
}

.dropdown-username.error svg path{
    fill: var(--primary-500);
}

.dropdown-username__content{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.dropdown-username__active-item{
    display: flex;
    align-items: center;
    gap: 10px;

}

.dropdown-username__active-item span{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.ico{
    transition: transform .3s ease-in-out;
}

.ico.active{
    transform: rotateZ(180deg);
    transition: transform .3s ease-in-out;
}

.dropdown-username__list{
    max-height: 260px;
    position: absolute;
    z-index: 30;
    left: 0;
    top: calc(100% + 10px);

    width: 100%;

    background-color: white;
    border-radius: 12px;
    border: 1px solid rgba(200,206,218, 0.375082);

    overflow-x: hidden;
}

.dropdown-username__list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    background-color: var(--white);

    padding: 22px 15px;
    transition: background-color .3s ease-in-out;
}

.dropdown-username__list-item:hover{
    background-color: var(--primary-100);
    transition: background-color .3s ease-in-out;
}

.dropdown-username__list-item:first-of-type{
    border-radius: 12px 12px 0 0 ;
}

.dropdown-username__list-item:last-of-type{
    border-radius:0 0 12px 12px ;
    padding: 14px 15px;
}

.dropdown-username__item-content{
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-username__item-content span{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--defaultTextColor);
}
</style>