<template>
    <div id="quick-order" class="modal modal-8 modal--show">
        <div class="modal__dialog modal__dialog--443">
            <!--  overflow-hidden -->
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header pb-[29px] px-[0px] pt-[0px] sm:pt-[42px] sm:pt[] sm:px-[47px] justify-center sm:justify-between">
                    <ul v-if="step === 1" class="type-item-list sm:hidden py-[7px] px-[12px]">
                        <li v-for="item in types" :key="item.label">
                            <a @click.prevent="() => handleSelect(item.value)" href="#" :class="{'active': contentType === item.value}" class="btn">{{ item.label }}</a>
                        </li>
<!--                        <li>-->
<!--                            <a href="#" class="btn active">Likes</a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a href="#" class="btn">Views</a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a href="#" class="btn">Comments</a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a href="#" class="btn">Shares</a>-->
<!--                        </li>-->
                    </ul>
                    <h6 class="font-bold text-[18px] leading-[24px] hidden sm:block">Quick order</h6>
                    <a href="#" class="btn btn-close hidden sm:block" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[30px] sm:pb-[74px] px-[28px] sm:px-[47px]">



                    <div  v-if="step === 1" class="form-group mb-[15px] hidden sm:block">
                        <label class="form-group-title mb-3">Custom Name</label>
                            <SelectBox
                                identifier='select-icon'
                                :options="types"
                                :value="contentType"
                                :data-image="require('@/assets/img/icons/icon-tiktok-logo.svg')"
                                @change="handleSelect($event)"
                            />


                    </div>

                    <StepOne
                        v-if="step === 1"
                        :platforms="dataprops.platforms"
                        :activePlatformType="contentType"
                        :isPopUp="true"

                        @nextStepPopUp="() => step = 2"
                    />

                    <Panel
                        v-else-if="step === 2"
                        :dataprops="dataprops"
                        :isPopUp="true"
                        :popUpContentType="contentType"

                        @closeModal="closeModal()"

                        @headerGoBackPopUp="headerGoBackPopUp"
                    />

                    <a  @click.prevent="closeModal()" href="#" class="btn btn--primary-outline px-[23px] py-[19px] rounded-[8px] mt-[10px] sm:hidden">
                        <span class="info">Cancel</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectBox from "../Base/SelectBox.vue"
import StepOne from "@/components/Panel/StepOne.vue";
import Panel from  "@/components/Panel/Panel.vue"
export default {
    components: {
        StepOne,
        SelectBox,
        Panel,
    },
    props: ['dataprops', 'selectedInCart', 'payload'],
    data() {
        return {
            step: 1,
            types: [
                {
                    value: "likes",
                    label: "Likes",
                },
                {
                    value: "followers",
                    label: "Followers",
                },
                {
                    value: "views",
                    label: "Views",
                },
                {
                    value: "comments",
                    label: "Comments",
                },
                {
                    value: "shares",
                    label: "Shares",
                }
            ],
            prices:{
                likes: [
                    {
                        ammount: 100,
                        type: 'likes',
                        price: '$6.99',
                        discountPrice: '',
                    },
                    {
                        ammount: 200,
                        type: 'likes',
                        price: '$7.99',
                        discountPrice: '$12.99',
                    },
                    {
                        ammount: 500,
                        type: 'likes',
                        price: '$17.99',
                        discountPrice: '$22.99',
                    },
                    {
                        ammount: 1000,
                        type: 'likes',
                        price: '$24.99',
                        discountPrice: '$32.99',
                    },
                    {
                        ammount: 2000,
                        type: 'likes',
                        price: '$32.99',
                        discountPrice: '$41.99',
                    },
                    {
                        ammount: 5000,
                        type: 'likes',
                        price: '$72.99',
                        discountPrice: '$41.99',
                    },
                ],
                followers:[
                    {
                        ammount: 100,
                        type: 'folowers',
                        price: '$6.99',
                        discountPrice: '',
                    },
                    {
                        ammount: 200,
                        type: 'folowers',
                        price: '$7.99',
                        discountPrice: '$12.99',
                    },
                    {
                        ammount: 500,
                        type: 'folowers',
                        price: '$17.99',
                        discountPrice: '$22.99',
                    },
                    {
                        ammount: 1000,
                        type: 'folowers',
                        price: '$24.99',
                        discountPrice: '$32.99',
                    },
                    {
                        ammount: 2000,
                        type: 'folowers',
                        price: '$32.99',
                        discountPrice: '$41.99',
                    },
                    {
                        ammount: 5000,
                        type: 'folowers',
                        price: '$72.99',
                        discountPrice: '$41.99',
                    },
                ],
                views:[
                    {
                        ammount: 100,
                        type: 'views',
                        price: '$6.99',
                        discountPrice: '',
                    },
                    {
                        ammount: 200,
                        type: 'views',
                        price: '$7.99',
                        discountPrice: '$12.99',
                    },
                    {
                        ammount: 500,
                        type: 'views',
                        price: '$17.99',
                        discountPrice: '$22.99',
                    },
                    {
                        ammount: 1000,
                        type: 'views',
                        price: '$24.99',
                        discountPrice: '$32.99',
                    },
                    {
                        ammount: 2000,
                        type: 'views',
                        price: '$32.99',
                        discountPrice: '$41.99',
                    },
                    {
                        ammount: 5000,
                        type: 'views',
                        price: '$72.99',
                        discountPrice: '$41.99',
                    },
                ],
                comments:[
                    {
                        ammount: 100,
                        type: 'comments',
                        price: '$6.99',
                        discountPrice: '',
                    },
                    {
                        ammount: 200,
                        type: 'comments',
                        price: '$7.99',
                        discountPrice: '$12.99',
                    },
                    {
                        ammount: 500,
                        type: 'comments',
                        price: '$17.99',
                        discountPrice: '$22.99',
                    },
                    {
                        ammount: 1000,
                        type: 'comments',
                        price: '$24.99',
                        discountPrice: '$32.99',
                    },
                    {
                        ammount: 2000,
                        type: 'comments',
                        price: '$32.99',
                        discountPrice: '$41.99',
                    },
                    {
                        ammount: 5000,
                        type: 'comments',
                        price: '$72.99',
                        discountPrice: '$41.99',
                    },
                ],
                shares: [
                    {
                        ammount: 100,
                        type: 'shares',
                        price: '$6.99',
                        discountPrice: '',
                    },
                    {
                        ammount: 200,
                        type: 'shares',
                        price: '$7.99',
                        discountPrice: '$12.99',
                    },
                    {
                        ammount: 500,
                        type: 'shares',
                        price: '$17.99',
                        discountPrice: '$22.99',
                    },
                    {
                        ammount: 1000,
                        type: 'shares',
                        price: '$24.99',
                        discountPrice: '$32.99',
                    },
                    {
                        ammount: 2000,
                        type: 'shares',
                        price: '$32.99',
                        discountPrice: '$41.99',
                    },
                    {
                        ammount: 5000,
                        type: 'shares',
                        price: '$72.99',
                        discountPrice: '$41.99',
                    },
                ],
            },
            contentType: 'likes',
            showSelect: false,
        };
    },
    computed: {},
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        handleSelect(event){
            console.log(event);
            this.contentType = event;
        },
        headerGoBackPopUp() {
            console.log('headerGoBackPopUp')
            this.step = this.step - 1
        }
    },
    mounted() {
        if (this.payload.selectedLabel) {
            this.$nextTick(()=>{
                // this.handleSelect(this.payload.selectedLabel.toLowerCase())
                this.contentType = this.payload.selectedLabel.toLowerCase()
            })

        }
    }
};
</script>

<style>

</style>