<template>
    <div id="write-review-2" class="modal modal-15 modal--show">
        <div class="modal__dialog modal__dialog--522">
            <div class="modal__content">
                <div class="modal__header pb-[17px] px-[28px] sm:px-[50px]">
                    <h6 class="font-bold text-[18px] leading-[24px]">Write a review</h6>
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <form @submit.prevent="handleSubmit" class="modal__body pb-[50px] px-[28px] sm:px-[50px]">
                    <div class="flex justify-start mb-[29px]">
                        <div class="rating-input">
                            <input type="radio" name="rating" id="rating-5" value="5" v-model="selectedRating">
                            <label for="rating-5"></label>
                            <input type="radio" name="rating" id="rating-4" value="4" v-model="selectedRating">
                            <label for="rating-4"></label>
                            <input type="radio" name="rating" id="rating-3" value="3" v-model="selectedRating">
                            <label for="rating-3"></label>
                            <input type="radio" name="rating" id="rating-2" value="2" v-model="selectedRating">
                            <label for="rating-2"></label>
                            <input type="radio" name="rating" id="rating-1" value="1" v-model="selectedRating">
                            <label for="rating-1"></label>
                        </div>
                    </div>

                    <div class="form-group mb-[19px]">
                        <label class="input-wraper input-wraper--label fill-input">
                            <input 
                                type="text" 
                                class="input font-semibold" 
                                placeholder="Your name" 
                                required
                                v-model="name"
                            >
                            <div class="label-input">
                                <span>Your name</span>
                            </div>
                        </label>
                    </div>

                    <div class="form-group mb-[19px]">
                        <label class="input-wraper input-wraper--label fill-input">
                            <input 
                                type="email"
                                class="input font-semibold"
                                placeholder="Your email"
                                required
                                v-model="email"
                            >
                            <div class="label-input">
                                <span>Your email</span>
                            </div>
                        </label>
                    </div>

                    <label class="textarea-wraper error textarea-wraper--label mb-[19px]" :class="{'bg-[#FFEEF1] border-primary-500' : hasError}">
                        <textarea 
                            type="text" 
                            class="textarea-input w-full font-semibold"
                            rows="5" 
                            placeholder="Your review"
                            v-model="review"
                        ></textarea>
                        <div class="label-input">
                            <span>Your review</span>
                        </div>
                    </label>
                    <span v-if="charactersLeft > 0" class="font-[600] text-[12px] leading-[16px] mb-[30px]" :class="{'text-primary-500' : hasError}">
                        Type {{charactersLeft}} more characters.
                    </span>
                    <span v-else="charactersLeft > 0" class="font-[600] text-[12px] leading-[16px] mb-[30px] text-success-500">
                        Ready to submit! You can continue typing if you wish.
                    </span>
                    <button type="submit" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] justify-start font-[700] text-[14px] leading-[19px]">
                        <span>Submit</span>
                        <div class="icon-right">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </button>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ordersApi } from '@/api/ordersApi';

export default {
    data() {
        return {
            name: '',
            email: '',
            review: '',
            selectedRating: 5,
            minCharacters: 30,
            hasError: false
        };
    },
    computed: {
        charactersLeft() {
            const result = this.minCharacters - this.review.length

            if(result <= 0)  this.hasError = false
            
            return result
        }
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        async handleSubmit() {
            this.hasError = false
            if(this.charactersLeft > 0) {
                this.hasError = true
                return
            }
            // console.log('Кликнули на кнопку!', this.selectedRating);
            const [first_name, last_name] = this.name.split(' ');
            const form = {
                platform_id: 9,
                email: this.email,
                first_name,
                last_name: last_name|| null,
                rating: +this.selectedRating,
                feedback: this.review
            }
            const response = await ordersApi.createAnonymReview(form)
        }
    },
}
</script>