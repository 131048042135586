<template>
   <div class="red-choices-wrap">
        <div class="w-full h-full" v-for="(product, index) in products.slice(0, sliceProducts)" :key="index">
            <label class="choice-label h-full flex" @click="selectedProduct = product">
                <input type="radio" name="product" :checked="product === selectedProduct" class="choice-input" style="display:none">
                <div class="choices-comment__item h-full w-full second-package">
                    <span class="choices-comment__icon">
                        <img src="@/assets/img/icons/icon-tiktok-logo.svg" alt="icon-tiktok-logo">
                    </span>
                    <span class="choices-comment__amount">{{ product.product_units }} <span>{{ platform }} </span> </span>
                    <span class="choices-comment__price">${{ product.base_price }}
                        <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]" v-show="product.strikethrough_price">
                            ${{ product.strikethrough_price }}
                        </span>
                    </span>
                </div>
            </label>
        </div>
        <div v-if="products.length > 6" class="col-choice">
            <label class="choice-label flex" @click="selectedProduct = dropDownOption">
                <input type="radio" name="product" class="choice-input" :checked="dropDownOption === selectedProduct" style="display:none">
                <div class="choices-comment__item w-full second-package">
                    <span class="choices-comment__icon"><img src="@/assets/img/icons/icon-tiktok-logo.svg" alt="icon-tiktok-logo"></span>

                    <Dropdown
                            :title="'title'"
                            class="product-select-drop"
                            :isProductDropdown="true"
                            :options="products.slice(5).map(el => ({ title: `${el.product_units} ${platform}`, value: el }))"
                            :value="dropDownOption"
                            @change="dropDownOption = $event.value; selectedProduct = $event.value"
                        />
                    <span class="choices-comment__price">
                        ${{ dropDownOption.base_price }}
                        <span class="text-[#C4C3C3] text-[10px] leading-[14px] line-through ml-[3px]" v-show="dropDownOption.strikethrough_price">
                            ${{ dropDownOption.strikethrough_price }}
                        </span>
                    </span>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
import Dropdown from '../Base/Dropdown.vue'

export default {
    name: "RedProductCard",
    props: {
        products: {
            type: Array,
            default:[]
        },
        platform: {
            type: String
        },
        sliceProducts:{
            type:Number
        }
    },
    components: {
        Dropdown
    },
    data() {
        return {
            dropDownOption: null,
            isDropdownOpen: false,
            selectedProduct:null,
        }
    },
    watch:{
       selectedProduct(value){
         this.$emit('change', value)
       } 
    }
}
</script>

<style scoped>
.red-choices-wrap{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 9px 11px;
    margin-bottom: 20px;
}

@media (max-width:600px){
    .red-choices-wrap{
        grid-template-columns: repeat(3,1fr);
        gap: 13px 6px;
    }

    .choices-comment__icon{
        display: none;
    }

    .choices-comment__amount span{
        display: none;
    }

    .choices-comment__price span{
        display: block;
    }
}
</style>