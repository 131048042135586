import axios from 'axios';
import { REST_URL } from '@/config';

// console.log(REST_URL);

class REST {
    constructor() {
        this.instance = axios.create({
            baseURL: REST_URL,
            //   timeout: 30000,
        });

        this.instance.interceptors.request.use(
            async (config) => {
                const token = window.localStorage.getItem('token') || ''

                // console.log(token, 'token in instance');
                config.withCredentials = true
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                return config;
            },
            (error) => {
                return Promise.reject(error)
            }
        )

    }

    formatSuccess(d, field) {
        return field && d[field] || d
    }

    formatError(err) {
        // console.log(err);

        return {
            hasError: true,
            error: { ...err }
        }
    }

    pushMessage(res, field) {
        const { message, success } = res;
        const serverMessage = {
            message, success, field
        }

        window.bus.$emit('serverMessage', serverMessage);
    }

    async request(
        url = '/',
        method = 'GET',
        data = null,
        dataField = null, // faqs
        timeout = 10000,
        responseType = null
    ) {
        try {
            if (url?.includes('/tools')) {
                this.instance.defaults.baseURL = "https://tinkws-tools.webstaginghub.com/api/";
            } else {
                this.instance.defaults.baseURL = REST_URL;
            }

            const result = await this.instance.request({
                url,
                method,
                data,
                // timeout,
                responseType
            }).then(res => {
                // if(dataField === 'orderGetInvoice') {
                //     console.log(res);
                //     return res
                // }
                if (data?.payment_type === 'free') {
                    return res
                }
                return res.data
            });

            // console.log(result, 'result')

            // debugger
            // console.log('RESPONSE', result);

            // if(dataField === 'tikTokUser' || dataField === 'orderGetInvoice') {
            //     return this.formatSuccess(result.data, dataField)
            // }

            if (result.message && result.message.length) this.pushMessage(result, dataField);

            return result.success &&
                this.formatSuccess(result.data || result, dataField) || result;
        } catch (err) {
            // тут в дата получаем обьект {password: 'some error'}
            console.warn(err)
            return this.formatError(err?.response?.data, dataField);

        }
        // finally {
        //     console.log('request done');
        // }

    }

}

const restInstance = new REST()

export default restInstance