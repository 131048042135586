<template>
    <div>
        <section @scroll="initStickyFooter" class="py-[60px] bg-white-100 promo-section section">
            <div class="container-px--lg w-full">
                <StepOne 
                    :platforms="dataprops.platforms"
                    :activePlatformType="contentType"
                    @change="changeCurrentProducts"
                />
            </div>
        </section>
        <section class="py-[60px] bg-grey--300 bg-primary-100-576 section">
            <div class="container-px--lg">
                <div class="heading-block items-center text-center">
                    <h2 class="heading heading-2 font-bold">
                        Frequently Asked Questions
                    </h2>
                </div>
                <div class="flex flex-col sm:mt-[53px] mt-[24px]">
                  
                    <FAQ :params="{
                        type: 'group',
                        slug: serviceSlugs[contentType]
                    }"/>

                    <div class="mt-[24px]">
                        <div class="heading-block items-center text-center">
                            <h2 class="heading heading-6 font-bold">
                                Didn’t find your answer?
                            </h2>
                            <div class="mt-[20px]">
                                <router-link to="/contact-us" class="btn btn--lg btn--primary rounded-[8px]">
                                    <span class="ico-24 mr-[20px] sm:hidden flex">
                                        <!-- <?php include "img/icons/ico-message.svg"; ?> -->
                                        <img src="@/assets/img/icons/ico-message.svg" alt="arrow">
                                    </span>
                                    <span class="info text-[14px] font-bold">
                                        Contact Us
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-[60px] bg-white-100 section">
            <div class="container-px--lg">
                <div class="heading-block items-center text-center">
                    <h2 class="heading heading-2 font-bold">
                        Here’s what our customers say
                    </h2>
                </div>
            </div>

            <ReviewsSlider />
            <!-- <ReviewsSection></ReviewsSection> -->
            <div class="container-px--lg">
                <div class="flex flex-col">
                    <div class="flex flex-col mt-[30px] sm:order-none order-1">
                        <div class="grade-row">
                            <div class="grade-row--option flex items-center">
                                <div class="grade-block mr-[19px] mb-2.5">
                                    <div class="grade-block-container">
                                        <div class="grade-number">4.9</div>
                                        <div class="grades-coll">of 5</div>
                                    </div>
                                </div>
                                <div class="flex flex-col mb-2.5">
                                    <p class="def-text--1 font-semibold">
                                        Tink.ws has been rated 
                                        excellent by 2321 clients!
                                    </p>
                                    <div class="rating mt-[9px]">
                                        <label for="star-1" class="rating-star ico-20">
                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                            </svg>
                                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                                        </label>
                                        <label for="star-2" class="rating-star ico-20">
                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                            </svg>
                                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                                        </label>
                                        <label for="star-3" class="rating-star ico-20">
                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                            </svg>
                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                        </label>
                                        <label for="star-3" class="rating-star ico-20">
                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                            </svg>
                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                        </label>
                                        <label for="star-3" class="rating-star ico-20">
                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                            </svg>
                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="grade-row--option flex items-center">
                                <div class="dbl-btns dbl-btns-10 dbl-btns-rows-576 justify-center">
                                    <a href="#" class="btn py-8 px-15 w-[170px] btn--primary-outline rounded-[8px] mb-2.5"
                                    @click.prevent="openModal('write-review')">
                                        <span class="info text-[14px] font-bold">
                                            Write a review
                                        </span>
                                    </a>
                                    <router-link to="/reviews" class="btn py-8 px-15 w-[170px] btn--primary rounded-[8px] mb-2.5">
                                        <span class="info text-[14px] font-bold">
                                            All reviews
                                        </span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col sm:mt-[53px] mt-[20px]">
                        <div class="accordeons flex flex-col">
                            <div class="accordeons-container flex flex-col">
                                <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                    <div class="review-2--header">
                                        <div class="profile-card">
                                            <div class="user-icon rounded-full">AM</div>
                                            <div class="profile-content flex flex-col ml-[25px]">
                                                <div class="profile-content--item mb-[-5px]">
                                                    <h3 class="profile-title mr-[30px] pb-[5px]">Athar Malakooti</h3>
                                                    <div class="status flex items-center pb-[5px]">
                                                        <span class="ico ico-20"><img src="@/assets/img/verified-account.svg" alt="verified-account"></span>
                                                        <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                            Verified customer
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="profile-content--item">
                                                    <div class="rating mt-[8px]">
                                                        <label for="star-1" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                                                        </label>
                                                        <label for="star-2" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                                                        </label>
                                                        <label for="star-3" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                        </label>
                                                        <label for="star-3" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                        </label>
                                                        <label for="star-3" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-2--body">
                                        <p class="def-text--1 font-semibold">
                                            <q>
                                                Best fast reliable service 
                                                time and time again.
                                            </q>
                                        </p>
                                    </div>
                                    <div class="review-2--footer">
                                        <div class="flex items-center def-text--4 text-text2">
                                            <span class="ico ico-20"><img src="@/assets/img/tik-tok-iocn.svg" alt="tik-tok-iocn"></span>
                                            <span class="info font-medium ml-[5px]">
                                                500 Real likes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                    <div class="review-2--header">
                                        <div class="profile-card">
                                            <div class="user-icon rounded-full"><img src="@/assets/img/user-logo.png" alt="user-logo"></div>
                                            <div class="profile-content flex flex-col ml-[25px]">
                                                <div class="profile-content--item mb-[-5px]">
                                                    <h3 class="profile-title mr-[30px] pb-[5px]">@wimwillems</h3>
                                                    <div class="status flex items-center pb-[5px]">
                                                        <span class="ico ico-20"><img src="@/assets/img/verified-account.svg" alt="verified-account"></span>
                                                        <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                            Verified customer
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="profile-content--item">
                                                    <div class="rating mt-[8px]">
                                                        <label for="star-1" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-1" id="star-1" class="rating-input">
                                                        </label>
                                                        <label for="star-2" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-2" id="star-2" class="rating-input">
                                                        </label>
                                                        <label for="star-3" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                        </label>
                                                        <label for="star-3" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                        </label>
                                                        <label for="star-3" class="rating-star ico-20">
                                                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.324 26.2784L7.01832 31.692L8.98653 20.2257L0.649082 12.1053L12.1712 10.4324L17.324 0L22.4768 10.4324L33.9989 12.1053L25.6614 20.2257L27.6296 31.692L17.324 26.2784Z" fill="#FCB622"></path>
                                                            </svg>
                                                            <input type="radio" name="star-3" id="star-3" class="rating-input">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-2--body">
                                        <p class="def-text--1 font-semibold">
                                            <q>
                                                Best fast reliable service time and time again. 
                                                Great app feature for marketing!! 🔥
                                            </q>
                                        </p>
                                    </div>
                                    <div class="review-2--footer">
                                        <div class="flex items-center def-text--4 text-text2">
                                            <span class="ico ico-20"><img src="@/assets/img/tik-tok-iocn.svg" alt="tik-tok-iocn"></span>
                                            <span class="info font-medium ml-[5px]">
                                                500 Real likes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="accordeon-footer max-w-max mx-auto flex flex-col align-center">
                                <a href="#" class="btn flex-col text-center def-text--1 text-primary-500">
                                    <router-link to="/reviews" class="font-semibold">
                                        See more
                                    </router-link>
                                    <span class="ico ico-20 mt-[4px]">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <TryForFreeSection class="py-[60px] bg-black-100 section"></TryForFreeSection>
        <section class="py-[60px] bg-white-100 section">
            <div class="container-px--lg">
                <div class="row justify-between">
                    <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12 col-12 flex flex-col justify-center">
                        <div class="mw-420 mx-auto-768">
                            <div class="heading-block w-full items-center-768 text-center-768">
                                <div class="flex items-center mb-[-20px] flex-col sm:flex-row">
                                    <div class="flex-block flex-block--56 rounded-[13px] bg-black-100 sm:mr-[20px] mr-[0px] mb-[20px]">
                                        <img src="@/assets/img/tinkws-logo--short.svg" alt="tinkws-logo--short">
                                    </div>
                                    <h2 class="heading heading-2 font-bold mb-[20px]">
                                        Get TinkWs App
                                    </h2>
                                </div>
                                <div class="mt-[20px] def-text--2 font-semibold">
                                    <p class="text-secondary-500">
                                        One stop application for all your social 
                                        media engagement needs.
                                    </p>
                                </div>
                                <div class="mt-[20px] mw-250">
                                    <a href="#" class="btn w-full btn--lg2 btn--primary rounded-[8px] justify-between" @click.prevent="openModal('download-app')">
                                        <span class="ico ico-24 mr-[10px]">
                                            <!-- <?php include "img/icons/ico-download.svg"; ?> -->
                                        </span>
                                        <span class="flex-auto info text-[14px] font-bold">
                                            Download Now
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 flex flex-col
                    justify-center my-[-60px] py-[60px] d-none-768">
                        <div class="flex flex-col mb-[-60px] ml-auto" style="width:450px;">
                            <div class="image-bunner" 
                            style="max-height:300px;">
                                <div class="bunner">
                                    <img src="@/assets/img/img-phone.png" alt="img-phone">
                                </div>
                                
                                <div class="dekor">
                                    <div style="left: 40px;bottom:-80px; animation: shape1 4s ease infinite;">
                                        <img src="@/assets/img/shape-1.svg" alt="shape-1">
                                    </div>
                                    <div style="right: 0;top: 0; animation: shape1 4s ease infinite;">
                                        <img src="@/assets/img/shape-2.svg" alt="shape-2">
                                    </div>
                                    <div style="top:50px;left:70px;">
                                        <img src="@/assets/img/shape-3.svg" alt="shape-3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <StickyButton v-if="currentProducts.length" :products="currentProducts" :activeGroup="activeGroup" :isShow="isShowSticky"/>
    </div>
</template>

<script>
import StickyButton from '@/components/Base/StickyButton.vue';
import ReviewsSlider from "../components/Base/ReviewsSlider.vue"
import FAQ from "@/components/Dashboard/FAQ.vue";
import TryForFreeSection from "@/components/Order/TryForFreeSection.vue";
// import Panel from "@/components/Panel/Panel.vue"
import StepOne from "@/components/Panel/StepOne.vue";

export default {
    props: ['dataprops'],
    components: {
        ReviewsSlider,
        FAQ,
        TryForFreeSection,
        StepOne,
        StickyButton
    },
    data(){
        return{
            serviceSlugs: {
                followers: 'buy-tiktok-followers',
                likes: 'buy-tiktok-likes',
                views: 'buy-tiktok-views',
                comments: 'buy-tiktok-comments',
                shares: 'buy-tiktok-shares'
            },
            currentProducts:[],
            activeGroup:null,
            isShowSticky:false,
            scrollPosition:0
        }
    },
    computed:{
        // dataprops(){
        //     return testData
        // },
        contentType() {
            return this.$route.params.type
        },
    },
    methods: {
        openAcordeon(item){
            item.isActive = !item.isActive;
        },
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
        changeCurrentProducts(value){
            this.currentProducts = value.products
            this.activeGroup = value.group
        },
        initStickyFooter(){
            if(document.body.clientWidth<=767){
                this.isShowSticky = window.scrollY>this.scrollPosition && window.scrollY>=500
                this.scrollPosition = window.scrollY
            }
        }
    },
    created(){
        window.addEventListener('scroll',this.initStickyFooter);
    },
    destroyed(){
        window.removeEventListener('scroll',this.initStickyFooter);
    },

    mounted(){
    }
   
    
}
</script>

<style scoped>
/* .rating-input > label {
    background-image: url('@/img/icons/icon-star-silver.svg');
} */

</style>