<template>
        <div id="heared-mobile-not-log" class="modal modal-1 modal--show">
            <div class="modal__dialog modal__dialog--443">
                    <div data-menu class="modal__content" :class="{active:isShow}">
                        <div class="modal__header mb-0 pb-0">
                            <a href="#" class="btn btn-close ml-auto" data-close-modal="" @click.prevent="closeModal()">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                                </svg>
                            </a>
                        </div>
        
                        <div class="modal__body px-0">
                            <div v-if="isLogged" class="modal__account account pr-[23px] pl-[18px] mt-[5px]">
                                <div class="flex items-center gap-[15px] mb-[20px]">
                                    <div class="w-[60px] h-[60px]">
                                        <img class="w-full h-full" src="@/assets/img/user-92x92.png" alt="ava">
                                    </div>
                                    <div>
                                        <p class="text-[18px]/[24px] font-bold  text-defaultTextColor">{{ user.name }}</p>
                                        <p class="text-[15px]/[20px] font-semibold  text-primary-500 mb-[9px]">{{ user.email }}</p>
                                        <div class="flex items-center gap-[20px]">
                                            <div class=" cursor-pointer w-[22px] h-[22px]" @click="moveTo('/settings')">
                                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.926 10c0-.761.41-1.464 1.074-1.837v-.001l-.718-2.404A2.105 2.105 0 0 1 17.064 2.7l-2.06-1.603A2.104 2.104 0 0 1 11.634 0L8.342.008A2.104 2.104 0 0 1 4.98 1.102L2.922 2.717A2.105 2.105 0 0 1 .715 5.76L0 8.175A2.104 2.104 0 0 1 1.051 10c0 .752-.4 1.448-1.051 1.825l.715 2.415a2.105 2.105 0 0 1 2.206 3.043l2.06 1.615a2.104 2.104 0 0 1 3.362 1.094l3.29.008a2.104 2.104 0 0 1 3.37-1.097l2.062-1.602a2.105 2.105 0 0 1 2.218-3.058L20 11.837A2.106 2.106 0 0 1 18.926 10zM10 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm2-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" fill="currentColor"/></svg>
                                            </div>
                                            <div class="cursor-pointer  flex items-center justify-center w-[22px] h-[22px]" @click="moveTo('/discounts')">
                                                <img class="w-full" src="@/assets/img/icons/percent-icon.svg" alt="">
                                            </div>
                                            <div class="" v-click-outside="closeNotificationDropdown" :class="{ 'dropdown-open': isNotificationDropdopdownOpen }" data-dropdown="dropdown">
                                                <div class="cursor-pointer w-[22px] h-[22px] flex items-center justify-center btn notification text-defaultTextColor" :class="{'text-primary-500':isNotificationDropdopdownOpen}" @click.prevent="toggleNotificationDropdown" :data-count="dataprops.notifications.length" data-role="button">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.66 11.6C17.91 10.85 17.5 9.86 17.5 8.8V7.75C17.5 3.48 14.02 0 9.75 0C5.48 0 2 3.48 2 7.75V8.8C2 9.86 1.59 10.85 0.84 11.6C0.3 12.14 0 12.86 0 13.63C0 15.21 1.29 16.5 2.87 16.5H16.63C18.21 16.5 19.5 15.21 19.5 13.63C19.5 12.86 19.2 12.14 18.66 11.6Z" fill="currentColor"></path>
                                                        <path d="M12.75 18H6.75C6.34 18 6 18.34 6 18.75C6 19.16 6.34 19.5 6.75 19.5H12.75C13.16 19.5 13.5 19.16 13.5 18.75C13.5 18.34 13.16 18 12.75 18Z" fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                                <div class="dropdown__body notification-dropdown" :class="{ 'is-open': isNotificationDropdopdownOpen }" data-role="dropdown">
                                                    <div class="notification-dropdown__header">
                                                        <div class="flex justify-between items-center max-w-[220px] w-full mx-auto">
                                                            <span class="font-semibold">Notifications ({{ getNotificationCount }})</span>
                                                            <button @click="handleReadNotifications" class="btn btn--primary-outline rounded-[12px] px-[12px] py-[10px]">
                                                                <span class="text-[10px]">Mark as read</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="notification-dropdown__body">
                                                        <div
                                                            class="notification-dropdown__body--item"
                                                            :class="{ 'read': notification.is_read }"
                                                            v-for="(notification, index) in dataprops.notifications" :key="index"
                                                        >
                                                            <div class="flex justify-between items-center mb-[4px]">
                                                                <span class="font-semibold def-text--1">{{ notification.content }}</span>
                                                                <span class="text-[#8F9BB3]">{{ new Date(notification.created_at).toLocaleDateString() }}</span>
                                                            </div>
                                                            <div class="flex justify-between items-center">
                                                                <p class="text-secondary-500 def-text--4">{{ notification.description }}</p>
                                                                <router-link v-if="notification.link" :to="notification.link" class="btn">
                                                                    <img src="@/assets/img/icons/right-arrow-long.svg" alt="">
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between gap-[10px]"> 
                                    <p class="text-[15px] font-regular text-defaultTextColor">Balance: <span class="font-semibold">${{ user.balance }}</span></p>
                                    
                                    <p @click="moveTo('/add-funds')" class="cursor-pointer w-max text-[14px] text-defaultTextColor font-bold rounded-[8px] border-[1px] border-defaultTextColor border-solid px-[24px] py-[12px] hover:opacity-70 transition-opacity duration-300 ease-in-out" >
                                        Add funds
                                    </p>
                                </div>
                            </div>
        
                            <ul class="sidebar__list">
                                <li @click="closeModal">
                                    <router-link to="/service-page/likes" >
                                        <span class="sidebar-list-icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7643 1H7.23571C3.79309 1.00425 1.00354 3.79437 1 7.237V12.7656C1.00496 16.2072 3.79409 18.9958 7.23571 19H12.7643C16.2069 18.9957 18.9965 16.2056 19 12.763V7.23443C18.995 3.7928 16.2059 1.00425 12.7643 1Z" fill="currentColor" stroke="currentColor" stroke-width="1.2"></path>
                                                <path d="M13.546 11.2767H11.2767V13.546C11.2548 14.235 10.69 14.7822 10.0006 14.7822C9.31131 14.7822 8.74644 14.235 8.72457 13.546V11.2767H6.45529C5.98973 11.2915 5.55307 11.0516 5.31592 10.6507C5.07877 10.2498 5.07877 9.75153 5.31592 9.35063C5.55307 8.94973 5.98973 8.70979 6.45529 8.72457H8.72457V6.45529C8.70979 5.98973 8.94973 5.55307 9.35063 5.31592C9.75153 5.07877 10.2498 5.07877 10.6507 5.31592C11.0516 5.55307 11.2915 5.98973 11.2767 6.45529V8.72457H13.546C14.235 8.74644 14.7822 9.31131 14.7822 10.0006C14.7822 10.69 14.235 11.2548 13.546 11.2767Z" class="active-color" fill="#ffffff"></path>
                                            </svg>
                                        </span>
                                        New order
                                    </router-link>
                                </li>
                                <li @click="closeModal">
                                    <router-link to="/orders">
                                        <span class="sidebar-list-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                                <g clip-path="url(#clip0_1266_63795)">
                                                    <path d="M9.75 7C10.4404 7 11 6.44036 11 5.75C11 5.05964 10.4404 4.5 9.75 4.5C9.05964 4.5 8.5 5.05964 8.5 5.75C8.5 6.44036 9.05964 7 9.75 7Z" fill="currentColor"></path>
                                                    <path d="M15.75 0H3.75C1.68 0 0 1.68 0 3.75V11.75C0 13.82 1.68 15.5 3.75 15.5H5.25C5.95 15.5 6.63 15.84 7.05 16.4L8.35 18.13C8.68 18.58 9.19 18.83 9.75 18.83C10.31 18.83 10.82 18.57 11.15 18.13L12.45 16.4C12.87 15.84 13.55 15.5 14.25 15.5H15.75C17.82 15.5 19.5 13.82 19.5 11.75V3.75C19.5 1.68 17.82 0 15.75 0ZM9.75 3C11.27 3 12.5 4.23 12.5 5.75C12.5 7.27 11.27 8.5 9.75 8.5C8.23 8.5 7 7.27 7 5.75C7 4.23 8.23 3 9.75 3ZM14.09 12.42C13.98 12.47 13.87 12.5 13.76 12.5C13.49 12.5 13.22 12.35 13.09 12.09L12.64 11.2C12.43 10.77 12 10.51 11.52 10.51H7.99C7.51 10.51 7.09 10.77 6.87 11.2L6.42 12.09C6.24 12.46 5.79 12.61 5.41 12.43C5.04 12.24 4.89 11.79 5.07 11.42L5.52 10.53C5.99 9.59 6.93 9.01 7.98 9.01H11.51C12.56 9.01 13.5 9.59 13.97 10.53L14.42 11.42C14.61 11.79 14.46 12.24 14.08 12.43L14.09 12.42Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1266_63795">
                                                        <rect width="20" height="19" class="active-color" fill="#ffffff"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        My orders
                                    </router-link>
                                </li>
                                <li @click="closeModal">
                                    <router-link to="/contact-us">
                                        <span class="sidebar-list-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                                <g clip-path="url(#clip0_1266_63804)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 0.5C15.13 0.5 19.5 3.99 19.5 8.26C19.5 12.53 15.13 16.01 9.75 16.01C8.89 16.01 8.03 15.92 7.2 15.74L2.92 17.57C2.76 17.64 2.6 17.67 2.43 17.67C2.15 17.67 1.87 17.57 1.64 17.39C1.28 17.09 1.11 16.63 1.2 16.17L1.87 12.82C0.66 11.5 0 9.89 0 8.25C0 3.98 4.37 0.5 9.75 0.5ZM4.61111 7.16193C3.78268 7.16193 3.11111 7.8335 3.11111 8.66193C3.11111 9.49036 3.78268 10.1619 4.61111 10.1619C5.43954 10.1619 6.11111 9.49036 6.11111 8.66193C6.11111 7.8335 5.43954 7.16193 4.61111 7.16193ZM9.61111 7.16193C8.78268 7.16193 8.11111 7.8335 8.11111 8.66193C8.11111 9.49036 8.78268 10.1619 9.61111 10.1619C10.4395 10.1619 11.1111 9.49036 11.1111 8.66193C11.1111 7.8335 10.4395 7.16193 9.61111 7.16193ZM14.6111 7.16193C13.7827 7.16193 13.1111 7.8335 13.1111 8.66193C13.1111 9.49036 13.7827 10.1619 14.6111 10.1619C15.4395 10.1619 16.1111 9.49036 16.1111 8.66193C16.1111 7.8335 15.4395 7.16193 14.6111 7.16193Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1266_63804">
                                                        <rect width="20" height="18" class="active-color" fill="#ffffff"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        Message us
                                    </router-link>
                                </li>
                            </ul>
        
                            <div v-if="!isLogged" class="flex items-center justify-center gap-[30px] my-[50px] " >
                                <span class="cursor-pointer text-[15px] font-medium text-primary-500 px-[24px] py-[14px] underline hover:no-underline" @click.prevent="openModal('login')">
                                    Log in
                                </span>
                                <span class="cursor-pointer px-[24px] py-[14px] text-[15px] font-medium rounded-[8px] text-primary-500 hover:text-white-100 hover:bg-primary-500"
                                    @click.prevent="openModal('register')">
                                    Sign up
                                </span>
                            </div>
        
                            <div v-else>
                                <div @click.prevent="logOut" class="cursor-pointer w-max flex items-center gap-[16px] text-text font-medium text-[15px] py-[8px] mt-[15px] mb-[40px] ml-[40px] hover:text-primary-500 transition-colors duration-300 ease-in-out">  
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0952 3.9049C10.0952 4.16789 10.3084 4.38109 10.5714 4.38109H18.1905C18.9794 4.38109 19.619 5.02068 19.619 5.80966V18.1906C19.619 18.9796 18.9794 19.6192 18.1905 19.6192H10.5714C10.3084 19.6192 10.0952 19.8324 10.0952 20.0954C10.0952 20.3584 10.3084 20.5716 10.5714 20.5716H18.1905C19.5054 20.5716 20.5714 19.5056 20.5714 18.1906V5.80966C20.5714 4.4947 19.5054 3.42871 18.1905 3.42871H10.5714C10.3084 3.42871 10.0952 3.64191 10.0952 3.9049ZM8.05099 14.9968L5.05437 12.0001H15.3333C15.5963 12.0001 15.8095 11.7869 15.8095 11.5239C15.8095 11.261 15.5963 11.0478 15.3333 11.0478H5.05437L8.05099 8.05114C8.23695 7.86518 8.23695 7.56367 8.05099 7.37771C7.86503 7.19174 7.56352 7.19174 7.37755 7.37771L3.56803 11.1872C3.38207 11.3732 3.38207 11.6747 3.56803 11.8607L7.37755 15.6702C7.56352 15.8562 7.86503 15.8562 8.05099 15.6702C8.23695 15.4842 8.23695 15.1827 8.05099 14.9968Z" fill="currentColor"></path>
                                    </svg>
                                    <span>Log Out</span>
                                </div>
                                
                                <p class="cursor-pointer sidebar-subtext hover:opacity-70" @click="moveTo('/affiliate')">Affiliate dashboard</p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
</template>

<script>
import { otherApi } from '@/api/othersApi';

export default {
    props: ['dataprops'],
    data() {
        return {
            isShow:false,
            isLogged: false,
            isNotificationDropdopdownOpen:false
        };
    },
    computed: {
        user() {
            return this.dataprops?.user || {}
        },
        getNotificationCount() {
            return this.dataprops?.notifications?.length || 0
        }
    },
    watch: {
        'dataprops.user': {
            handler(newValue) {
                if (newValue) {
                    this.isLogged = true;
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        closeModal() {
            const menu = document.querySelector('[data-menu]')
            this.isShow = false

            menu.addEventListener('transitionend',()=>{
                document.body.classList.remove('scroll-hidden')
                this.$bus.$emit("modal", {
                    name: "",
                    payload: {},
                });

            })
        },
        openModal(name) {
            this.$bus.$emit('modal', {
                name: name,
                overlay: true,
                payload: {},
            })
        },
        logOut() {
            window.localStorage.removeItem('token')
            // this.$router.push('/')
            this.closeModal()
            this.$bus.updateDataprops('user', null)
            this.$route.params?.type ? '':this.$router.push('/service-page/likes')
        },
        moveTo(link) {
            this.$router.push(link)
            this.closeModal()
        },
        toggleNotificationDropdown(){
            this.isNotificationDropdopdownOpen = !this.isNotificationDropdopdownOpen;
        },
        closeNotificationDropdown(){
            this.isNotificationDropdopdownOpen = false;
        },
        async handleReadNotifications() {
            const response = await otherApi.readNotifications();
            
            if (response.notifications) {
                this.$set(this.dataprops, 'notifications', response.notifications)
            }
        },
        initAnimation(){
            setTimeout(()=>{
                this.isShow = true
            },100)
        }
    },
    mounted(){
        this.initAnimation()
    }
}
</script>

<style scoped>
.sidebar__list li a.active .sidebar-list-icon {
    background-color: unset;
}

.sidebar-list-icon {
    background-color: var(--white);
    transition: background-color .3s ease-in-out;
}

.sidebar__list li:hover .sidebar-list-icon {
    background-color: var(--primary-500);
    border-radius: 0 32px 32px 0;

    color: #444A58;
    transition: background-color .3s ease-in-out;
}

.sidebar__list li:hover .sidebar-list-icon .active-color {
    fill: var(--primary-500);
    text-decoration: none;
}

.modal__content{
    transform: translateY(100%);
    transition-property: transform;
    transition-duration: .5s;
}

.modal__content.active{
    transform: translateY(0);
    transition-property: transform;
    transition-duration: .5s;
}
.modal-1::-webkit-scrollbar{
    display: none;
}

</style>