<template>
    <section class="lg:py-[60px] lg:bg-[#EEF3FF] section flex flex-col flex-auto" :class="{'isPopUp': isPopUp}">
        <div class="container-px--lg flex flex-col flex-auto">
            <div class="flex items-center justify-center relative mb-4 mt-[14px] lg:hidden">
                <a @click.prevent="(e) => $emit('headerGoBackPopUp', e)" href="#" class="btn w-[40px] h-[40px] rounded-[11px] left-0 absolute">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </a>
                <span class="def-text--1 font-semibold">New order</span>
            </div>
            <div class="steps-flex flex flex-auto flex-col">
                <div class="custom-columns custom-columns--50 flex-auto">
                    <div  class="custom-col-6 steps-flex--form flex flex-col custom-col-12-1200  mb-[30px]">
                        <div class="add-to-cart__container">
                            <span class="title">Add extras</span>
                            <ul  v-if="extrasAvailable.length">
                                <li v-for="(opt) in extrasAvailable" :key="opt" class="extras-option">
                                    <label class="choose-option" @click.prevent="showExtras(opt)">
                                        <input type="checkbox" hidden :checked="availableExtrasShow[opt]" />
                                        <div class="checkbox-sub"></div>
                                        <span>Add {{ opt }}</span>
                                    </label>

                                    <div v-if="availableExtrasShow[opt]">
                                        <div v-if="extrasConfig[opt].groups.length > 1" class="tabs-head d-md-none mb-[20px] mt-[15px]" >
                                            <ul class="tabs__list whitespace-nowrap">
                                                <li class="w-full" v-for="(group, groupIndex) in extrasConfig[opt].groups" :key="groupIndex">
                                                    <a @click.prevent="changeExtrasGroup(group, opt)" href="#" class="tab-button" :class="{'active': extrasConfig[opt].selectedGroup.identifier === group.identifier}">
                                                        <span class="tab-text">{{ group.name }}</span>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>

<!--                                        {{ getProducts(extrasConfig[opt].selectedGroup) }}-->

                                        <div class="mt-[15px]" :key="opt + extrasConfig[opt].selectedProductId">
                                            <SelectBox
                                                :key="opt"
                                                identifier='select-price'
                                                :options="getProducts(extrasConfig[opt].selectedGroup)"
                                                :value="extrasConfig[opt].selectedProductId"
                                                @change="value => setExtrasProduct(value, opt)"
                                            />
                                        </div>

                                    </div>
                                </li>
                            </ul>

                            <p v-else >No recommended options</p>
                        </div>
                    </div>

                    <div class="custom-col-6 steps-flex--form flex flex-col custom-col-12-1200">
                        <div class="add-to-cart__container">
                            <div class="product-option rounded-[8px]" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">
                                    <path d="M9 10.0657V9.07559C8.646 9.0268 8.2888 9.00169 7.93131 9C3.55803 9 0 12.4537 0 16.698C0 19.3015 1.34073 21.6063 3.38597 23C2.01647 21.5786 1.25499 19.7051 1.25615 17.7591C1.25615 13.5752 4.71304 10.1633 9 10.0657Z" fill="#00F2EA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.297 17.6487C12.2272 19.508 10.6976 21 8.82212 21C7.70255 20.9989 6.65225 20.4589 6 19.5488C6.49777 19.8117 7.05225 19.9488 7.61511 19.9485C9.49001 19.9485 11.0205 18.4571 11.0895 16.5984L11.0965 0H15.2379C15.2382 0.352533 15.2708 0.704227 15.3356 1.05089H12.3035L12.297 17.6487ZM20.9994 6.68487V6.68499H21C20.9998 6.68495 20.9996 6.68491 20.9994 6.68487ZM20.9994 6.68487V5.7626C19.8852 5.76371 18.7947 5.4394 17.862 4.82987C18.6797 5.77139 19.7804 6.42221 20.9994 6.68487Z" fill="#00F2EA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5549 1C16.5543 2.35183 17.0679 3.65799 18 4.67573C16.6506 3.83821 15.7194 2.50976 15.4234 1H16.5549ZM4 16.0011C4.00228 14.1309 5.5904 12.6154 7.55028 12.6133C7.90579 12.6144 8.25902 12.6674 8.59801 12.7701V9.60978C8.62233 9.60935 8.6466 9.60857 8.6709 9.60779C8.70752 9.60661 8.7442 9.60543 8.78118 9.60543C9.13157 9.60706 9.48167 9.63126 9.82863 9.67828V13.7928C9.49762 13.6935 9.14695 13.6359 8.78118 13.6359C6.82102 13.6381 5.2329 15.1541 5.2309 17.0243C5.2309 17.7341 5.46592 18.4253 5.90262 19C4.73381 18.415 4.00114 17.2603 4 16.0011Z" fill="#FF004F"/>
                                    <path d="M20.7902 7.00082V10.1399C18.6378 10.1399 16.6444 9.47005 15.0161 8.33296V16.541C15.0161 20.6402 11.5898 23.9751 7.37777 23.9751C5.75 23.9751 4.24063 23.4753 3 22.6275C4.4419 24.1408 6.46661 25.0005 8.58705 25C12.7991 25 16.226 21.665 16.226 17.5664V9.35836C17.908 10.5355 19.9283 11.1678 22 11.1653V7.12531C21.5846 7.12531 21.1807 7.08145 20.7899 7" fill="#FF004F"/>
                                    <path d="M15.1798 16.3898V7.98723C16.8753 9.19253 18.9117 9.83952 21 9.83701V6.62381C19.7686 6.36139 18.6566 5.71133 17.8307 4.77096C16.4941 3.91174 15.5717 2.54888 15.2786 1H12.216L12.2095 17.57C12.1392 19.4256 10.5938 20.9151 8.69938 20.9151C7.56876 20.914 6.50754 20.3746 5.84897 19.4666C4.69126 18.8667 3.96525 17.6821 3.96384 16.39C3.9661 14.4714 5.53916 12.9166 7.48044 12.9144C7.84217 12.9144 8.18951 12.973 8.51795 13.0753V9.83311C4.35605 9.9296 1 13.3029 1 17.4394C1 19.4398 1.78639 21.2609 3.06769 22.621C4.35943 23.5198 5.90089 24.0014 7.48044 24C11.7264 24 15.1798 20.586 15.1798 16.3898Z" fill="black"/>
                                </svg>

                                <div class="product-option-info">
                                    <span>{{ addAddon ? postInfo.baseUnits + getAddons.units : postInfo.baseUnits }} {{ postInfo.category }}</span>
                                    <a @click.prevent="openPostsDetails" href="#" class="text-teal underline font-semibold">
                                        <div v-if="$route.params.type==='followers'" class="flex items-center gap-[10px] mt-[10px]">
                                            <div v-if="cart.bought[0].data.userData?.avatar_thumb?.url_list.length" class="w-[40px] h-[40px] rounded-full overflow-hidden">
                                                <img :src="cart.bought[0].data.userData.avatar_thumb.url_list[0]" alt="">
                                            </div>
                                            <div>@{{ cart.bought[0].data.username }}</div>
                                        </div>
                                        <span v-else >{{ postInfo.count ? postInfo.count + 'posts' : '' }} </span>
                                    </a>
                                </div>



                                <span class="product-option-price">${{ addAddon ? (postInfo.price + +getAddons.price).toFixedNoRounding(2) : postInfo.price }}</span>

                            </div>

                            <div v-if="getAddons" class="flex items-center justify-between mb-[16px]">
                                <p class="font-semibold text-text pr-10">Add + {{ getAddons.units }} {{ getAddons.label }} and save {{ getAddons.discount }}%</p>
                                <a v-if="!addAddon" @click.prevent="addAddon = true" class="whitespace-nowrap px-6 btn btn--primary md:px-[23px] py-[13px] rounded-[8px] def-text--1">
                                    <span class="info">Add for <span class="line-through opacity-60">${{ getAddons.base_price }}</span> ${{ getAddons.price }}</span>
                                </a>
                                <a v-else @click.prevent="addAddon = false" class="whitespace-nowrap px-6 btn btn--primary md:px-[23px] py-[13px] rounded-[8px] def-text--1">
                                    <span class="info">Cancel</span>
                                </a>
                            </div>

<!--                            <div class="product-option-select-sub rounded-[8px]" >-->
<!--                                <div class="product-option-select-sub-info">-->
<!--                                    <span>Gender targeting</span>-->
<!--                                    <span>Female</span>-->
<!--                                </div>-->

<!--                                <span class="product-option-price">+$2.52</span>-->

<!--                            </div>-->

<!--                            <div class="product-option-select-sub rounded-[8px]" >-->
<!--                                <div class="product-option-select-sub-info">-->
<!--                                    <span>Country targeting</span>-->
<!--                                    <span>United States</span>-->
<!--                                </div>-->

<!--                                <span class="product-option-price">+$2.52</span>-->

<!--                            </div>-->


                            <div class="product-option total rounded-[18px]" >
                                <span>Total</span>
                                <span>${{ countTotal }}</span>
                            </div>


                            <div class="col-12 mt-auto">
                                <button @click.prevent="addToCart" class="mt-8 btn btn--lg2 btn--primary rounded-[8px] w-full justify-between" :class="{'btn-disabled': false}">
                                    <span class="info text-[14px] font-bold">
                                        Continue
                                    </span>
                                    <span v-if="true" class="ico ico-24 ml-[10px]">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span v-else class="btn-loading">
                                        <Loader />
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddCommentModal
            v-if="commentsModalState.open"
            :postData="getPostDataForModal"
            :commentsPerPost="getPostProductCount"
            :commentsData="getCommentsDataForModal"
            @close="commentsModalState = {
                open: false,
                id: null
            }"
            @fillComment="fillComment"
        />

    </section>
</template>

<script>


import Loader from "@/components/Base/Loader.vue";
import {cartApi} from "@/api/cartApi";
import SelectBox from "@/components/Base/SelectBox.vue";
import AddCommentModal from "@/components/Modals/AddCommentModal.vue";

export default {
    components: {AddCommentModal, SelectBox, Loader},
    props: ['config', 'cart', 'isPopUp', 'productGroup', 'tiktokPlatform', 'activeGroup'],
    data() {
        return {
            extrasAvailable: [],
            postInfo: {
                category: null,
                count: null,
                price: null
            },
            addAddon: false,
            availableExtrasShow: {comments: false},
            extras: null,

            extrasConfig: {},

            commentsModalState: {
                open: false,
            },

            selectedMedia: {
                addedItems: {},
                comments: {},
                count: 0
            },
        }
    },
    computed: {
        getAddons() {
            const add = this.productGroup.addons
            const prod = this.config.selectedProduct
            if (add.checkboxOffer.enabled) {
                const units = Math.ceil((prod.product_units - (prod.product_units * (add.checkboxOffer.increasePercentage / 100))))
                const base_price = ((prod.base_price / prod.product_units) * units).toFixedNoRounding(2)
                return {
                    units: units,
                    base_price: base_price,
                    price: (base_price - (base_price * (add.checkboxOffer.discountPercentage / 100))).toFixedNoRounding(2),
                    discount: add.checkboxOffer.discountPercentage,
                    label: this.productGroup.name.toLowerCase()
                }
            }
            return false
        },
        countTotal() {
            let extrasPrice = 0
            Object.entries(this.availableExtrasShow).forEach(el => {
                if (el[1]) {
                    extrasPrice += this.extrasConfig[el[0]].selectedProduct.base_price
                }
            })

            return (this.postInfo.price + (this.addAddon ? +this.getAddons.price : 0) + extrasPrice).toFixedNoRounding(2)
        },
        getExtrasOptions() {

        },
        getPostDataForModal() {
            // Выбор поста для заполнения комментариев
            return this.config?.mediaData?.posts.find(el => el.id + '' === this.commentsModalState.id + '')
        },
        getCommentsDataForModal() {
            // Выбор поста для заполнения комментариев
            return this.selectedMedia.comments[this.commentsModalState.id] || {}
        },
        getPostProductCount() {
            return Math.floor(this.selectedMedia.count ? (this.extrasConfig['comments'].selectedProduct.product_units / this.selectedMedia.count) : this.extrasConfig['comments'].selectedProduct.product_units)
        },

    },
    methods: {
        async addToCart(e, filled=false) {

            const extrasEntries = Object.entries(this.availableExtrasShow)

            if (this.availableExtrasShow['comments'] && !filled && this.extrasConfig['comments'].selectedGroup.identifier === "tiktok.customComments") {
                this.checkFilledComments()
                // console.log('yes')
                return
            }
           
            this.cart.bought[0].data.addons = {checkboxOffer: Number(this.addAddon)}

            // const forExtrasProd = this.cart.selectedProductsForExtras.map(el => el.fullForCart)
            const newCart = []

            extrasEntries.forEach((el,idx) => {
                if (el[1]) {
                    const config = this.extrasConfig[el[0]]
                    const isCustom = el[0] === 'comments' && config.selectedGroup.identifier === "tiktok.customComments"

                    const units = Math.floor(config.selectedProduct.product_units / this.cart.selectedProductsForExtras.length)

                    let obj = {}

                    if (el[0] === 'followers') {
                        obj = {
                            data: {
                                addons: (config.selectedGroup.addons ? {checkboxOffer: 0} : {}),
                                mediaData: [{
                                        socialData: this.config.userData,
                                        units: config.selectedProduct.product_units
                                    }],
                                userData: this.config.userData,
                                userName: this.config.username
                            },
                            identifier: new Date().getTime() + '-' + idx,
                            product_id: config.selectedProductId
                        }
                    } else {
                        obj = {
                            data: {
                                addons: (config.selectedGroup.addons ? {checkboxOffer: 0} : {}),
                                mediaData: this.cart.selectedProductsForExtras.map(el => ({socialData: el.fullForCart,
                                    units,
                                    ...(isCustom ? {customComments: Object.values(this.selectedMedia.comments[el.id])} : {})
                                })),
                                userData: this.config.userData,
                                userName: this.config.username
                            },
                            identifier: new Date().getTime() + '-' + idx,
                            product_id: config.selectedProductId
                        }
                    }

                    newCart.push(obj)
                }
            })
            
            const res = await cartApi.addCartData([...this.cart.bought, ...newCart])

            if (res) {
                this.$bus.updateDataprops('cartData', res.cart)
                this.$emit('closeModal')
                if (this.$route.path !== '/cart') {
                   await this.$router.push(`/cart`)
                }
            }
        },
        showExtras(opt) {
            this.availableExtrasShow = {...this.availableExtrasShow, [opt]: !this.availableExtrasShow[opt]}
            const groupProduct = this.extrasConfig[opt].selectedGroup
            const productId= this.getProducts(groupProduct)[0].id
            this.setExtrasProduct(productId,opt)
        },
        changeExtrasGroup(group, extra) {
            this.extrasConfig = {...this.extrasConfig, [extra]: {...this.extrasConfig[extra], selectedProductId: this.getProducts(group)[0].id, selectedGroup: group, selectedProduct: this.getProducts(group)[0]}}
        },
        getProducts(group) {
            return group.products.filter(p=> p.product_units/this.postInfo.count>= group.min_units).map(el => ({label: el.title, value: el.id, price: `$${el.base_price}`,price_old:el.strikethrough_price?`$${el.strikethrough_price}`:null, id:el.id , units:el.product_units,base_price:el.base_price}))
        },
        setExtrasProduct(id, opt) {
            if (!id) return
            if (opt === 'comments') {
                this.$forceUpdate()
            }

            this.extrasConfig = {...this.extrasConfig, [opt]: {...this.extrasConfig[opt], selectedProductId: id, selectedProduct: this.extrasConfig[opt].groups.find(el => el.identifier === this.extrasConfig[opt].selectedGroup.identifier).products.find(el => el.id === id) }}
        },
        async fillComment(data) {

            // const comments = this.selectedMedia.addedItems.map(c=>({[c.id]:{...data.value}}))
            this.selectedMedia.comments = {
                ...this.selectedMedia.comments,
                [data.key]: {...data.values}
            }

            // this.selectedMedia.comments = {...comments}

            await this.checkFilledComments()
        },
        checkFilledComments() {
            const remaining = this.selectedMedia.addedItems
            const filled = this.selectedMedia.comments

            const commentsPerPost = 5

            const arr = Object.entries(remaining)
            const postNeeded = arr.length

            const checkArr = []

            for (let i = 0; i < arr.length; i++) {

                const el = arr[i];
                const [key] = el

                if (!filled[key]) {
                    // filled[key] = {}
                    this.commentsModalState = {
                        open: true,
                        id: key,
                    }
                    return
                }

                if (filled[key] && Object.keys(filled[key]).length >= commentsPerPost) {
                    checkArr.push(true)
                    if (checkArr.length !== postNeeded){
                        continue
                    }

                    this.commentsModalState = {
                        open: false,
                        id: null
                    }

                    this.addToCart(null,true)

                    return
                } else {
                    this.commentsModalState = {
                        open: true,
                        id: key,
                    }
                    return
                }
            }
        },

        openPostsDetails() {
            const product = this.productGroup.products.find(p=>p.id===this.cart.bought[0].product_id)
            const item = this.cart.bought[0]
            const payload = {...item,product:product, product_group:this.productGroup}
            this.openModal('cart-details', payload)
        },

        openModal(name, payload){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: payload ? payload : {},
            })
        },
    },
    created() {
        if (this.config?.selectedProduct) {

            const regex = /\d+\s*\w+/;

            const category = (this.config.selectedProduct.title).replace(/\d+/g, '')

            this.postInfo = {
                category,
                count: this.cart.selectedProductsForExtras.length,
                price: this.config.selectedProduct.base_price,
                baseUnits: this.config.selectedProduct.product_units
            }
        }


        this.cart.selectedProductsForExtras.forEach(el => {
            this.selectedMedia.addedItems[el.id] = el
        })

        this.selectedMedia.count = this.cart.selectedProductsForExtras.length

        const configForGroups = {
            'tiktok.likes': ['comments', 'views', 'shares', 'followers'],
            'tiktok.followers': [],
            'tiktok.randomComments': ['views', 'shares', 'followers'],
            'tiktok.customComments': ['views', 'shares', 'followers'],
            'tiktok.shares': ['views', 'shares', 'followers', 'comments'],
            'tiktok.views': ['views', 'shares', 'followers', 'comments'],
        }

        this.extrasAvailable = configForGroups[this.activeGroup]



        for (let i = 0; i < this.extrasAvailable.length; i++) {
            const extrasName = this.extrasAvailable[i]
            this.availableExtrasShow[extrasName] = false

            const extras = this.tiktokPlatform[extrasName]

            this.extrasConfig[extrasName] = {

                groups: this.tiktokPlatform[extrasName].groupItems,
                selectedGroup: this.tiktokPlatform[extrasName].groupItems[0],
                selectedProduct: this.tiktokPlatform[extrasName].groupItems[0].products[0],
                selectedProductId: this.tiktokPlatform[extrasName].groupItems[0].products[0].id
            }
        }

        // console.log('addon',this.cart)
    }
}
</script>

<style>
.add-to-cart__container {
    border-radius: 10px;
    background: #FFF;

    padding: 22px 30px;
}

.add-to-cart__container:first-child {
    padding: 30px 20px;
}

.add-to-cart__container .title {
    display: block;
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    margin-bottom: 24px;
}

.choose-option {
    padding: 17px;
    border-radius: 8px;
    background: #F8F8F8;
}



.choose-option  {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.choose-option  span {
    display: block;
    margin-left: 15px;
    pointer-events: none;
    user-select: none;
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
}

.checkbox-sub {
    position: relative;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 8px;
}
.checkbox-sub:after {
    content: "";
    position: absolute;
    display: none;
}

/* Стили для отображения галочки, когда чекбокс отмечен */
.choose-option  input:checked ~ .checkbox-sub:after {
    display: block;
}
.choose-option  input:checked ~ .checkbox-sub {
    background: #0E0E0E;
}

/* Рисование галочки */
.checkbox-sub:after {
    left: 9px;
    top: 3px;
    width: 8px;
    height: 15px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.extras-option + .extras-option {
    margin-top: 16px;
}

.product-option {
    display: flex;
    align-items: center;
    padding: 14px 20px;
    background: #F8F8F8;

    margin-bottom: 22px;
}

.product-option.total {
    display: flex;
    justify-content: space-between;

    color: #000;

    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.product-option-info {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 100%;

}

.product-option-select-sub {
    display: flex;
    align-items: center;
    padding: 13px 20px 16px 18px;
    border: 1px solid rgba(0, 0, 0, 0.08);

    margin-bottom: 16px;
}

.product-option-info span {
    color: #000;

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.product-option-info span:last-child {
    color: #22E5E0;
    text-decoration-line: underline;
}

.product-option-select-sub-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.product-option-select-sub-info span {
    color: #77838F;

    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}
.product-option-select-sub-info span:last-child {
    color: #000;
    font-size: 14px;
    font-weight: 600;

    display: block;
    margin-top: 4px;
}

.product-option-price {
    display: block;
    margin-right: auto;
    color: #FE2C55;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.isPopUp, .isPopUp .container-px--lg, .isPopUp .custom-col-6.steps-flex--form {
    padding: 0;
}

.isPopUp {
    background: unset;
}

</style>