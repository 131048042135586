<template>
    <div class="container mx-0 container-dash">
        <div class="lg:flex items-center justify-between mb-[25px] hidden ">
            <div class="flex items-center">
                <router-link to="/settings" href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px] mr-[20px] hidden lg:flex">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </router-link>
                <h6>Account details</h6>
            </div>
        </div>
        <div class="flex items-center justify-between relative py-[20px] lg:hidden">
            <a href="#" class="btn w-[40px] h-[40px] bg-primary-100 rounded-[11px]">
                <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
            </a>
            <span class="def-text--1 font-semibold">Account details</span>
            <div class="w-[40px] h-[40px] rounded-full overflow-hidden my-[2px]">
                <img class="w-full h-full" src="@/assets/img/ava-40x40.png" alt="">
            </div>
        </div>
        <div class="row gutters-30" style="--bs-gutter-y: 22px;">
            <div class="col-lg-6">
                <div class="card-setting sm:bg-secondary-100 p-0 card-rounded">
                    <div class="card-body p-0 sm:p-[20px]">
                        <div class="flex items-center justify-between mb-[20px]">
                            <h6>General details</h6>
                            <a v-if="!isEditing" @click.prevent="isEditing = true" href="" class="btn underline font-bold text-[14px]">Edit</a>
                            <div v-else>
                                <a class="btn underline font-bold text-[14px] mr-[10px]" @click.prevent="isEditing = false">Cancel</a>
                                <a class="btn underline font-bold text-[14px]" @click.prevent>Save</a>
                            </div>
                            
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label"
                                    :class="{ 'invalid': userName.firstName.touched && !userName.firstName.isValid }"  style="background-color: transparent;">
                                <input 
                                    type="text" 
                                    id="first-name" 
                                    class="input font-semibold" 
                                    placeholder="First name"
                                    v-model="userName.firstName.value"
                                    :disabled="!isEditing"
                                    @blur="onFieldBlur(userName, ['firstName'])"
                                >
                                <div class="label-input">
                                    <span>First name</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label" 
                            :class="{ 'invalid': userName.lastName.touched && !userName.lastName.isValid }" style="background-color: transparent;">
                                <input 
                                    type="text" 
                                    id="last-name" 
                                    class="input font-semibold" 
                                    placeholder="Last name"
                                    v-model="userName.lastName.value"
                                    :disabled="!isEditing"
                                    @blur="onFieldBlur(userName, ['lastName'])"
                                >
                                <div class="label-input">
                                    <span>Last name</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label"
                                    :class="{ 'invalid': userEmail.email.touched && !userEmail.email.isValid }" style="background-color: transparent;">

                                <input 
                                    type="email" 
                                    id="email-address" 
                                    class="input font-semibold flex-auto w-auto"
                                    placeholder="Email"
                                    v-model="userEmail.email.value"
                                    disabled
                                    @blur="onFieldBlur(userEmail, ['email'])"
                                >
                                <span v-if="!isEditing" class="badge text-[12px] mr-[20px] px-[15px]" :class="dataprops.user.email_verified_at ? 'bg-teal' : 'bg-primary-500 '">
                                    <span v-if="dataprops.user.email_verified_at">Verified</span>
                                    <span v-else>Not Verified</span>
                                </span>
                                <a v-else @click.prevent="" href="" class="btn underline font-bold text-[14px] mr-[20px]">Change email</a>
                                <div class="label-input">
                                    <span>Email</span>
                                </div>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper fill-input input-wraper--label" style="background-color: transparent;">
                                <div class="flex items-center font-semibold input px-[15px] flex-auto w-auto">
                                    <img src="@/assets/img/icons8-google.svg" class="mr-[10px]" alt="">
                                    Google Account
                                </div>
                                <span v-if="!isEditing" class="badge bg-teal text-[12px] mr-[20px] px-[15px]">
                                    <span class="info">Connected</span>
                                </span>
                                <a v-else @click.prevent="" href="" class="btn underline font-bold text-[14px] mr-[20px]">Link different account</a>
                                <div class="label-input">
                                    <span>Linked account</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <!-- <hr class="hr my-[20px] sm:hidden"> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card-setting sm:bg-secondary-100 p-0 card-rounded">
                    <div class="card-body p-0 sm:p-[20px]">
                        <h6 class="mb-[18px]">Saved accounts</h6>
                        <div class="form-group mb-5">
                            <label class="input-wraper input-wrapper-ico--left mb-5" style="background-color: transparent;" v-for="account in accountsList" :key="account.id">
                                <span class="input-icon-user">
                                    <img :src="account.image" alt="">
                                </span>
                                <input type="text" class="input font-semibold !text-black-100" :value="`@${account.name}`" readonly="">
                                <a @click.prevent="removeAccount(account.id)" href="" class="btn mr-[20px]">
                                    <span class="btn-icon">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.88427 7.00067L13.5933 2.29032C13.9396 1.95586 14.0785 1.46057 13.9566 0.994829C13.8346 0.529083 13.4709 0.165357 13.0052 0.043448C12.5394 -0.0784609 12.0441 0.0604198 11.7097 0.406711L6.99933 5.11573L2.29032 0.406711C1.95586 0.0604198 1.46057 -0.0784609 0.994829 0.043448C0.529083 0.165357 0.165357 0.529083 0.043448 0.994829C-0.0784609 1.46057 0.0604198 1.95586 0.406711 2.29032L5.11573 6.99933L0.406711 11.7097C0.0604198 12.0441 -0.0784609 12.5394 0.043448 13.0052C0.165357 13.4709 0.529083 13.8346 0.994829 13.9566C1.46057 14.0785 1.95586 13.9396 2.29032 13.5933L6.99933 8.88427L11.7097 13.5933C12.0441 13.9396 12.5394 14.0785 13.0052 13.9566C13.4709 13.8346 13.8346 13.4709 13.9566 13.0052C14.0785 12.5394 13.9396 12.0441 13.5933 11.7097L8.88427 6.99933V7.00067Z" fill="#77838F"></path>
                                        </svg>
                                    </span>
                                </a>
                            </label>
                        </div>
                        <div class="form-group mb-5">
                            <label class="input-wraper input-wrapper-ico--left" style="background-color: transparent;">
                                <span class="input-icon">
                                    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7112 11.1192C16.6285 11.1268 16.5451 11.1319 16.4611 11.1319C15.5097 11.1319 14.6735 10.6432 14.1867 9.90309C14.1867 11.8434 14.1867 14.0515 14.1867 14.0885C14.1867 15.7971 12.8014 17.1818 11.0934 17.1818C9.38536 17.1812 8 15.7958 8 14.0878C8 12.3792 9.38536 10.9945 11.0934 10.9945C11.1583 10.9945 11.2213 11.0002 11.2843 11.004V12.5287C11.2206 12.5211 11.1589 12.5096 11.0934 12.5096C10.2215 12.5096 9.51455 13.2166 9.51455 14.0885C9.51455 14.9603 10.2209 15.6673 11.0934 15.6673C11.9658 15.6673 12.7358 14.98 12.7358 14.1082C12.7358 14.0732 12.7511 7 12.7511 7H14.2077C14.3445 8.30264 15.3965 9.331 16.7112 9.42518V11.1192Z" fill="black"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8 4H7.2C5.43269 4 4 5.43269 4 7.2V16.8C4 18.5673 5.43269 20 7.2 20H16.8C18.5673 20 20 18.5673 20 16.8V7.2C20 5.43269 18.5673 4 16.8 4ZM5.6 7.2C5.6 6.31634 6.31634 5.6 7.2 5.6H16.8C17.6837 5.6 18.4 6.31634 18.4 7.2V16.8C18.4 17.6837 17.6837 18.4 16.8 18.4H7.2C6.31634 18.4 5.6 17.6837 5.6 16.8V7.2Z" fill="black"/>
                                    </svg>
                                </span>
                                <input type="text" class="input font-semibold" placeholder="@username" v-model="newAccountName">
                            </label>
                        </div>
                        <a @click.prevent="addAccount" href="#" class="btn btn--primary-outline px-[23px] py-[20px] rounded-[8px] w-full text-[14px]">
                            <span class="info">Add account</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { dashboardApi } from '@/api/dashboardApi';
    import { platformsApi } from '@/api/platformsApi';
    import { profileApi } from '@/api/profileApi';
    import formValidation from '@/mixins/formValidation';
    export default{
        data() {
            return {
                isEditable: false,
                // accountsList: [],
                newAccountName: "",
                userName: false,
                userEmail: false,
                changesSaved: false,
                isEditing: false
            };
        },
        props: ['dataprops'],
        mixins: [formValidation],
        computed:{
            accountsList(){
                return this.dataprops.autofillData || []
            }
        },
        watch: {
            'dataprops': {
                handler(newValue) {
                    if(!newValue) return
                    this.setValue(this.userName, 'firstName', this.dataprops.user.first_name);
                    this.setValue(this.userName, 'lastName', this.dataprops.user.last_name);
                    this.setValue(this.userEmail, 'email', this.dataprops.user.email);
                    // if(newValue.autofillData) {
                    //     this.accountsList = newValue.autofillData
                    // }
                },
                deep: false,
            }
        },
        methods: {
            openModal(name){
                this.$bus.$emit('modal',{
                    name: name,
                    overlay: true,
                    payload: {
                        login: ()=> this.isLogged = true,
                    },
                })
            },
            async addAccount(){
                // this.accountsList.push({id: new Date(), name: this.newAccountName}) 
                // this.newAccountName = "";
                const scrapperResponse = await platformsApi.getTikTokUser(this.newAccountName);
                // console.log('scrapper resp', scrapperResponse)
                if(scrapperResponse.hasError) {
                    // console.log('here1')
                    return
                }
                // console.log('here2')
                const autofillResponse = await dashboardApi.addAutofillsData({
                    name: this.newAccountName,  // имя пользователя
                    image: scrapperResponse.data.avatar_168x168.url_list[0],
                    platform_id: 9
                })

                await this.refreshData()
                // this.accountsList.push(autofillResponse)
                this.newAccountName = "";
                // console.log('autofill', autofillResponse,this.dataprops,autofillData)
                // this.$bus.updateDataprops('autofillData', [...this.payload.dataprops.paymentMethods, response.payment_method]);
            },
            async removeAccount(id){
                const response = await dashboardApi.removeAutofillsData(id);

                await this.refreshData()
                // this.accountsList = this.accountsList.filter(accout => accout.id !== id)
            },
            async refreshData(){
                const autofillData = await dashboardApi.getAllAutofills()
                autofillData ? this.$bus.updateDataprops('autofillData', autofillData) : null
            },
            async saveChanges() {

                // this.$bus.$emit('modal',{
                //         name: 'change-email',
                //         overlay: true,
                //         payload: {
                //             // publicCode: '21312312321',
                //             // newEmail: this.userEmail.email.value,
                //             dataprops: this.dataprops
                //         },
                //     })

                //     return

                this.isEditable = false;

                const isValid = this.validate(this.userName) && this.validate(this.userEmail)
                // console.log(isValid, 'validate')
                if (!isValid) return

                const actualName = this.userName.firstName.value + ' ' + this.userName.lastName.value
                const actualEmail = this.userEmail.email.value

                const promises = [
                    this.dataprops.user.name !== actualName ? profileApi.update({...this.getValues(this.userName)}) : null,
                    this.dataprops.user.email !== actualEmail ? profileApi.emailChangeConfirm(this.getValues(this.userEmail).email) : null
                ]

                const [nameResp, emailResp] = await Promise.allSettled(promises).then(res => res.map(el => el.value))
                // console.log('name resp', nameResp)
                if(nameResp && !nameResp?.hasError) {
                    this.changesSaved = true;
                    setTimeout(() => {
                        this.changesSaved = false;
                    }, 2000)
                    this.$set(this.dataprops.user, 'name', `${this.userName.firstName.value} ${this.userName.lastName.value}`);
                }
                if(!emailResp || emailResp.hasError) return;
                this.$bus.$emit('modal',{
                    name: 'change-email',
                    overlay: true,
                    payload: {
                        publicCode: emailResp.publicCode,
                        newEmail: this.userEmail.email.value,
                        dataprops: this.dataprops
                    },
                })
            }

        },
        created() {
            this.userName = this.createForm({
                firstName: {
                    value: '',
                    required: true
                },
                lastName: {
                    value: '',
                    required: true
                },
            });

            const emailValidator = (val) => /\S+@\S+\.\S+/.test(val);
            this.userEmail = this.createForm({
                email: {
                    value: '',
                    required: true,
                    validators: [
                        emailValidator
                    ]
                }
            });

            this.setValue(this.userName, 'firstName', (this.dataprops.user.name || '').split(' ')[0]);
            this.setValue(this.userName, 'lastName', (this.dataprops.user.name || '').split(' ')[1]);

            this.setValue(this.userEmail, 'email', this.dataprops.user.email);
            // this.accountsList = this.dataprops.autofillData || []
        }
    }
</script>

<style scoped>

    .invalid {
        border: 1.5px solid red;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset !important;
        
        height: 100%;
    }

    input[readonly]:-webkit-autofill {
        -webkit-text-fill-color: rgba(0,0,0,0.3);
        -moz-transition: all .5s ease; 
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        -pie-transition: all .5s ease;
        transition: all .5s ease;
    }

</style>