<template>
    <div id="log-in" class="modal modal-19 modal-login modal--show">
        <div class="modal__dialog modal__dialog--587">
            <div class="modal__content">
                <div class="modal__header">
                    <a href="#" class="btn btn-close" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6818 11.6366L3 11.6366" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="#222B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[57px] px-[27px] sm:px-[125px]">
                    <div class="flex justify-center mb-[42px]">
                        <div class="decor-box-black">
                            <img src="@/assets/img/logo-login.png">
                        </div>
                    </div>
                    <h3 class="text-[24px] leading-[32px] font-[700] mb-[16px] w-[234px] mx-auto text-center">Welcome back
                    </h3>
    
                    <span class="text-[14px] leading-[18px] font-[600] text-secondary-500 text-center mb-[32px]">Enter your
                        details to sign in.</span>
    
                    <div class="form-group mb-[19px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{ 'error': loginForm.email.touched && !loginForm.email.isValid }">
                            <input 
                                type="text" 
                                id="email" 
                                class="input font-semibold" 
                                v-model="loginForm.email.value"
                                @blur="onFieldBlur(loginForm, ['email'])"
                                placeholder="email"
                                :disabled="loginForm.email.disabled"
                            >
                            <div class="label-input">
                                <span>Email Address</span>
                            </div>
                        </label>
                    </div>
    
                    <div class="form-group mb-[19px]">
                        <label class="input-wraper fill-input input-wraper--label" :class="{ 'error': loginForm.password.touched && !loginForm.password.isValid }" >
                            <input 
                                type="password" 
                                id="password" 
                                class="input font-semibold" 
                                v-model="loginForm.password.value"
                                @blur="onFieldBlur(loginForm, ['password'])"
                                placeholder="password"
                                :disabled="loginForm.password.disabled"
                            >
                            <div class="label-input">
                                <span>Password</span>
                            </div>
                        </label>
                    </div>
    
                    <button @click.prevent="login" class="btn btn--primary px-[27px] py-[19px] icon-btn rounded-[8px] font-[700] text-[14px] leading-[19px] mb-[19px]" :class="{ 'btn-disabled':  isLoading }">
                        <span v-if="!isLoading"> Log in</span>
                        <span v-else class="btn-loading w-[24px] h-[24px]">
                            <Loader :color="'#FE2C55'"/>
                        </span>

                    </button>
    
                    <div class="or-decor-wrap mb-[20px]">
                        <span class="or-decor"></span>
                        <span class="px-[16px]">or</span>
                        <span class="or-decor"></span>
                    </div>
    
                    <a :href="getGoogleUrl()" class="btn border text-text px-[27px] py-[18px] icon-btn rounded-[8px] font-[600] text-[14px] leading-[17px] mb-[18px]">
                        <div class="mr-[15px] flex">
                            <img src="@/assets/img/google.png">
                        </div>
                        <span>Continue with Google</span>
                    </a>
    
                    <a @click.prevent="openModal('forgot-password', { email: loginForm.email.value })" href="#" class="btn font-[600] text-center text-primary-500 mb-[62px] sm:mb-[32px] text-[12px] leading-[24px]">Reset
                        my
                        password
                    </a>
    
                    <span class="block text-center text-[12px] leading-[24px]">Don’t have an account ?
                        <a @click.prevent="openModal('register')" href="#" class="btn font-[600] text-primary-500">Sign up</a>
                    </span>
    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authApi } from '@/api/authApi';
import { profileApi } from '@/api/profileApi';
import formValidation from '@/mixins/formValidation';
import { GOOGLE_URL } from '@/config';
import Loader from '@/components/Base/Loader.vue';

export default {
    components:{Loader},
    data() {
        return {
            loginForm: {},
            isLoading:false
        };
    },
    mixins: [formValidation],
    computed: {},
    created() {
        const emailValidator = (val) => /\S+@\S+\.\S+/.test(val);

        let emailVal = this.$attrs?.payload?.email || ''

        this.loginForm = this.createForm({
            email: {
                value: emailVal,
                require: true,
                validators: [emailValidator]
            },
            password: {
                value: '',
                require: true,
            },
        })
    },
    methods: {
        getGoogleUrl() {
            return GOOGLE_URL;
        },
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        openModal(name, payload) {
            this.$bus.$emit('modal', {
                name: name,
                overlay: true,
                payload,
            })
        },
        async getUser() {
            const res = await profileApi.user()

            if (res.hasError) {

            } else {
                this.$bus.updateDataprops('user', res);
                this.closeModal()
            }
        },
        async login() {
            this.isLoading = true
            const isValid = this.validate(this.loginForm)

            if (!isValid) {
                return
            }

            const loginData = {
                ...this.getValues(this.loginForm),
            }

            this.disableField(this.loginForm, ['email', 'password'])


            const response = await authApi.login(loginData)



            if (!response || response.hasError) {
                console.error(' LOGIN ERROR');
                return
            }

            // ! убрать чтоб вернуть правильную логику
            if (response.token) {
                window.localStorage.setItem('token', response.token)
                await this.getUser()

            } else {
                this.openModal('verify-email', { twoFa: true, email: loginData.email, password: loginData.password, getUser: this.getUser })
            }

            this.enableField(this.loginForm, ['email', 'password'])
            this.isLoading = false
        }
    },
}
</script>

<style scoped>
.btn-loading {
    transform: scale(0.3);
    max-width: 19px;
    max-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>